@import "../../assets/css_general/InvisionExportStyles";
.eventPriceImg{
  width:50px;
  height:50px;
  @media(max-width: 768px){
    width:30px;
    height:30px;
  }
}
.emailInput{
  padding:8px 0px;
  width:100%;
    outline: 0;
    color:#7A7883;
    border-width: 0 0 2px;
    border-color: #F4F2F2;
  :focus {
    border-color: #7A7883;
  }
  // ::placeholder{
  //   // color: #b3b8c1;
  //   color: pink !important;
  // }
}
::placeholder{
  color: #b3b8c1;
}
.modal-dialog {
  max-width: 70%;
  margin: 1.75rem auto;
  position: relative;
  @media (max-width:768px) {
    max-width: 100%;
  }
  .Cruz {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
.modal-content{
  border-radius: 15px!important;

}
  .modal-body {
    padding: 1rem;
    @media(max-width:768px){
      padding:25px
    }
    @media(max-width:500px){
      padding:12px;
    }
   .Div-g{
     width: 48%;
    }
    .Div-m {
      width: 45%;

      &:last-child {
        margin-left: auto;
      }
    }

    .Lab {
      color: #7A7883;
      font-weight: 600;
      font-size: 13px;
      margin: 10px 0;
      margin-top: 5px;
    }

    .Redondo {
      border: 1px solid #b3b8c1;
      border-radius: 50px;
      padding: 10px 15px;
      padding-right: 4px;
      margin-bottom: 15px;
      width: 100%;
    }

    .form-check-label {
      margin-top: 10px;
      font-size: 16px;
      color: #7A7883;

      input {
        // margin-top: 7px;
      }

      a {
        color: $color-primary-color;
      }
    }

    .Logo {
      width: 160px;
    }

    h1 {
      @media (max-width:768px){
        font-size: 1.2rem;
      }
      font-size: 1.85rem;
      font-weight: 600;
      margin-top: 25px;
    }

    .NombreCat {
      margin-left: 25px;
    }

    .Ticket {
      font-weight: 600;
      font-size: 33px;
    }

    label {
      font-size: 18px;
margin: 0;

    }

    .Title {
      font-size: 21px;
      font-weight: 600;
      margin: 25px 0;
    }

    .Title2 {
      font-size: 26px;
      font-weight: 600;
      margin: 5px 0;
      @media (max-width:768px){
        font-size:22px !important;
      }
    }

    .Go {
      font-size: 16px;
      color: $color-primary-color;
      font-weight: 600;
      margin: 20px 0;
      display: inline-block;
      margin-bottom: 0;
    }

    .Video {
      width: 75%;
      max-width: 75%;
      height: 350px;
    }

  }

}

.Div-redonda-check{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid $color-grey-secondary;
  position: absolute;
  right: 0;
  bottom: -15px;
  cursor: pointer;
}

.Div-redonda-checked{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid $color-grey-secondary;
  position: absolute;
  background-color: $color-grey-secondary;
  right: 5px;
  bottom: -10px;
  cursor: pointer;
}

.Radio-redonda{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid $color-grey-secondary;
  // position: absolute;
  // right: 0;
  // bottom: -15px;
  cursor: pointer;
}
.ButtonManagerContainer {
  display: flex;
  flex-direction: row;
  position: fixed;
  box-shadow: 25px;
  width: auto;
  max-width: 60vw;
  flex-wrap: wrap;
  bottom: 0;
  left: 20vw;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 10;
  -webkit-box-shadow: 0px 7px 27px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 7px 27px 5px rgba(0, 0, 0, 0.4);
}

.ButtonManagerItemFirstElement{
  border-top-left-radius: 10px;
}

.ButtonManagerItemLastElement{
  border-top-right-radius: 10px;
}

.ButtonManagerItem{
  cursor: pointer;
  margin-bottom: 0!important;
  margin-top: 0!important;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ButtonManagerItem img{
  width: 25px;
  height: 25px;
}

.ButtonManagerItem h4{
  margin: 0!important;
  padding: 0!important;
}

.ButtonManagerItem:hover{
  background-color: rgb(245, 245, 245);
}

.ButtonManagerCollapser{
  cursor: pointer;
  margin-bottom: 0!important;
  margin-top: 0!important;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


.ButtonManagerCollapser img{
  width: 10px;
  height: 10px;
}

.ButtonManagerCollapserAbsolutePosition{
  position: absolute;
  top: 5%;
  left: 0;
}


@media (max-width: 880px){

  .ButtonManagerContainer{
    max-width: none;
    left: 0;
  }
}


@media (min-width:880px) and ( max-width:1200px){

  .ButtonManagerContainer{
    max-width: 80vw;
    left: 10vw;
  }
}


@media (min-width:1200px) and ( max-width:1650px){

  .ButtonManagerContainer{
    align-items: center;
    justify-items: center;
    min-width: 80vw;
    max-width: 80vw;
    left: 5vw;
  }
}



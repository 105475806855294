@import "../../assets/css_general/InvisionExportStyles.scss";

.btn-private {
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    z-index: 100;
    border-radius: 0;
    left: 0;
    width: 100vw;
  }
}

@media (max-width: 991px) {
  .privateRequestButtonContainer {
    justify-content: center !important;
  }
}

.profile_div .img_profile_influencer {
  width: 100%;
  max-width: 200px;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px $color-primary-color;
  padding: 5px;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .img_profile_influencer {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .influencerAboutMeDescription {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}

.datos_influencer_profile {
  .RatingPoints {
    // margin-bottom: 1rem;
    margin-top: 0rem;
  }
  margin-left: 5rem;
}
.aboutme_div_influencer {
  // margin-top: -5rem;
  //margin-bottom: 3rem;
}

.InfluencerPresentationVideo {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
.InfluencerPresentationVideo div, .InfluencerPresentationVideo video{
    border-radius: 10px;
}

@media (min-width: 991px) {
  .InfluencerPresentationVideo {
      max-width: 600px;
  }
}
.aboutme_influencer {
  @media (max-width: 768px) {
    // margin-left: 3rem !important;
    padding-right: 0rem !important;
    width: 100%;
  }
  width: 100%;
}
.profile_div_mobile {
  padding-top: 2rem !important;
}
.profile_div {
  padding-top: 5rem;
  .grey {
    font-size: 1em;
  }
  .username_profile {
    color: $color-primary-color;
  }
  .img_profile {
    width: 17%;
    // margin-left: 3rem;
    height: 79%;
  }
  .div_user {
  }
  .about_user {
    margin-left: 3rem;
  }
  .name_profile {
    font-size: 2.5em;
  }
  .friends,
  .followers {
    color: black;
    font-weight: 500 !important;
    font-size: 1.3em !important;
  }

  .social-icons {
    display: flex;

    div {
      padding-right: 5px;
    }
  }
}
.showall {
  color: $color-primary-color;
}

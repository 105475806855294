.container_my_transactions{
    padding-top: 9rem;
}

#mytransactions{
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.Search-div-custom {
    width: 100%;
}

.text-center{
    text-align: center;
}

.float-right {
    float: right;
}

.font-x-large {
    font-size: x-large;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.inline-grid {
    display: inline-grid;
}

.margin-top-15 {
    margin-top: 15px;
}


.margin-top-10 {
    margin-top: 10px;
}

.PrivateMeeting{
    input[type="checkbox"]:checked + label::after {
        content: "";
        display: block;
        width: 0em;
        height: 0em;
        position: absolute;
        background-repeat: no-repeat;
        background-color: red;
        opacity: 0.7;
        margin-top: -11.5%;
        margin-left: 3000rem !important;
        border-radius: 1rem;
        transition: 1s;
        cursor: pointer;
    }
    .pointer {
        cursor: pointer;
    }
    padding-top: 0rem;
    .div-nombre-admin{
        position: relative;
        top: 0.3rem;
    }
    .request_p{
        color: grey;
    }
    .UsuPuntuacion7 .Foto {
        border-radius: 50%;
        border: 1px solid #ff5a60;
        margin-right: 0px;
        align-self: center;
        width: 63px;
        margin-left: 0;
    }
    .UsuPuntuacion7 .Icono {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 16px;
    }
    .UsuPuntuacion7 .Nombre {
        font-size: 17px;
        font-weight: 600;
        display: block;
        margin-left: 0.2rem;
    }
    .stars_request{
        float: right;
        position: relative;
        bottom: 3rem;
        right: 2rem;
    }
    .target_request{
        padding: 2rem;
        border: 1px solid #D8DCE5;
        border-radius: 8px;
    }
    .private_want{
        display: inline-block;
        font-size: 28px;
        color: black;
        margin-top: 1rem;
    }
    .fun{
        width: 34px;
    }
    .list-fun{
        width:100%;
        list-style: none;
    }
    input[type=checkbox] {
        transform: scale(1.5);
      }
      .reason{
        border-radius: 8px;
        border: 1px solid grey;
        width: 18rem;
    height: 2.3rem;  
      }
      .div-reason::after{
        content: "";
        width: 19rem;
        display: block;
        height: 1px;
        background: #D8DCE5;
        position: relative;
        top: 1rem;
        margin-bottom: 2rem;
      }
      .meeting_people{
        padding: 0 2rem 0 0rem;
      }
      .small_grey{
          color: grey;
          font-size: 14px;
      }
      .people{
        box-shadow: 0px 0px 1px 23px white, 0px 0px 1px 24px #D8DCE5; 
      }
      .input_people:hover {
        cursor: pointer;
    }
    .input_people{
        width: 100%;
        border-radius: 21px;
        border: 1px solid #D8DCE5;
        height: 3rem;
    }
    .input_people_date{
        width: 100%;
        border-radius: 21px;
        border: 1px solid #D8DCE5;
        height: 3rem;
        padding: 16px;
        text-align: left;
    }
    .textarea_request{
        width: 100%;
        height: 5rem;
        border: 1px solid #D8DCE5;
    }
}
.demo input[type="checkbox"], .demo2 input[type="checkbox"],.demo3 input[type="checkbox"],.demo4 input[type="checkbox"] {
	display: none;
}
label{
    color: grey;
}
.demo input[type="checkbox"] + label span, .demo2 input[type="checkbox"] + label span,.demo3 input[type="checkbox"] + label span,.demo4 input[type="checkbox"] + label span {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin: -1px 10px 0 -5px;
    vertical-align: middle;
    border: 1px solid #B6BAC3;
    background-size: cover;
    cursor: pointer;
    border-radius: 15px;
}
.demo input[type="checkbox"]:checked + label span, .demo2 input[type="checkbox"]:checked + label span,.demo3 input[type="checkbox"]:checked + label span,.demo4 input[type="checkbox"]:checked + label span {
	background-image: url("../../assets/img/icons/ic-check.svg");
	background-size: cover;
}

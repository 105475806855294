@import "../../../assets/css_general/InvisionExportStyles";


.Influencer_card{
  display: flex;
  margin-bottom:15px;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 10px 17px 0px rgba(227,227,227,1);
  -moz-box-shadow: 0px 10px 17px 0px rgba(227,227,227,1);
  box-shadow: 0px 10px 17px 0px rgba(227,227,227,1);
width: 100%;
  padding-top: 15px;

  .Div-img{
    display: flex;
    justify-content: center;
    position: relative;
    .Foto{
      width: 120px;
      border: 1px solid red;
      border-radius: 50%;
    }

    .Icono{
      height: 30px;
      width: 30px;
      align-self: flex-end;
      margin-left: -30px;
      // margin-bottom: 5px;
    }
  }
  .Div-txt{
    display: flex;
    flex-direction: column;


    p{
      word-break: break-all;
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
      color: #333;
      font-size: 18px;
      font-weight: 600;
      text-align: center;

    }
  }
  .Puntuacion{
    width: 95px;
    margin: 0 auto;
    margin-bottom: 0px;
    margin-bottom: 15px;
  }
}
@media (min-width: 1600px) {
  .Influencer_card{
  .Div-img{
    .Foto{
      width: 140px;
    }
    .Icono{
      height: 35px;
      width: 35px;
    }

  }
}
}
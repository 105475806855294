.messages_details {
    padding-top: 9rem;
}
.group-chat{
    border-radius: 50px;
    padding: 0px 14px;
    font-size: 15px;
    height: 30px;
    border: 0;
    background-color: #ff5a60;
    color: #fff;
    position: relative;
    left: 5rem;
}
.title_messages{
    font-size: 23px;
    font-weight: 600!important;
}
.chat2::before{
    content: "";
    width: 1px;
    display: block;
    height: 100%;
    background: #E2E6EF;
    margin-left: auto;
    position: absolute;
    right: -33px;
    top: -33px;
}
.date{
    display: flex;
    height: 1rem;
    margin: 0 auto;
}
.bar{
    width: 12rem;
    background: #E2E6EF;
    height: 1px;
    margin-left: 1rem;
    position: relative;
    top: 0.7rem;
}
.date::after{
    content: "";
    width: 1px;
    height: 48.9rem;
    display: block;
    background: #E2E6EF;
    position: relative;
    left: 5.5rem;
    bottom: 1rem;
}
.group_name{
    margin-left: 4rem;
    font-size: 15px;
    color: grey;
}
.gm{
    font-size: 19px;
    margin-left:-1rem;
}
.messages_details{
    .Search-div-peq {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 108%;
        margin-top: 15px;
        position: relative;
        margin-left: -1.5rem;
    }
    .Search-div-peq i {
        font-size: 30px;
        position: absolute;
        left: 18px;
        top: 0px;
        color: #B3B8C1;
    }
    .Search-div-peq input {
        padding-left: 60px;
        padding-top: 18px;
        padding-bottom: 20px;
        font-size: 16px;
        border-radius: 50px;
        margin-top: -4px;
    }
}



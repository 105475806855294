.titulo_ban{
    margin-top: 3rem;
    margin-bottom: 1rem;
}
.leave_moderation{
    border-radius: 50px;
    font-size: 17px;
    height: 32px;
    border: 0;
    background-color: #FF5A60;
    color: #fff;
    width: 11rem;
}
.div_ban{
    margin-left: 3rem;
}
#profile_influencer {
  top: 1.3rem !important;
}
.div_logout{
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 18px;
}
.boton_go{
  padding: 0 48px!important;
}
.dropdown-item:hover{
  font-weight: 600 !important;

  background: unset!important;
}
 .LogOut{
   color: #ff5a60!important;
   cursor: pointer;
 }
 .dropdown-toggle::after {
   display: none;
 }
 .profile::before {
  content: "";
  position: absolute;
  top: -13px;
  left: 73%;
  width: 0;
  height: 0;
  border-width: 0 14px 17px;
  border-style: solid;
  border-color: transparent transparent white;
}
 .profile{
   top: 14px!important;
   left: -5rem !important;
   border-radius: 14px;
 }
 .dropdown-item {
   padding: 1.25rem 4rem;
   position: relative;
   right: 1rem;
   @media (max-width:768px){
    padding: 0.75rem 2.5rem !important;
  }

 }
 .dropdown-item img {
   position: relative;
   right: 1rem;
 }

.Logued-div {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  @media (max-width:768px) {
    align-self: center;
    
    img.circularLogo {
      position: absolute;
      width: 14px !important;
      height: 14px !important;
     
    }
    img{
        margin-left: 10px !important;
      
      align-self: center !important;
    }
  }
  .profile-img-container {
    position: relative;
    justify-content: center;

  }
  
  img {
    margin-left: 15px;
    border: 1px solid #ff5a60;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    object-fit: cover;
  }
  img.circularLogo {
    position: absolute;
    width: 18px;
    height: 18px;
    right: 0;
    bottom: 0;
  }

  div {
  
    display: flex;
    flex-direction: column;
    text-align: right;

    .Name {
      color: white;
      font-size: 21px;
    }

    .Mode {
      color: #7A7883;
      font-size: 17px;
    }


  }
}
@import "../../../assets/css_general/InvisionExportStyles";


.react-datepicker {
  border: 0;

  .react-datepicker__header {
    border: 0;
    background:none!important;
  }

  .react-datepicker__current-month{
    font-size: 27px;
    font-weight: 400;
    // text-align: left;
    margin-bottom: 10px;
  }
  .react-datepicker__navigation {
    // display: none;
  }

  .react-datepicker__day-names{
    border-bottom: 1px solid lightgray;
  }



}

.react-datepicker {
  font-size: 1.2em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.8em;
  line-height: 3em;
  margin: 0.166em;


}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 0.3em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: -1rem;
  top:2rem;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: -5rem;
  top: 2rem;
}

.react-datepicker__week{
  font-size: 17px;
  // text-align: left;
}


.react-datepicker__week .react-datepicker__day{
  margin-right: 5.3%;
  margin-bottom: 1em;
  line-height: 23px;

  border-radius: 50%;
  height:23px;
  width: 23px;
}

.react-datepicker__week:first-child{
  margin-top: 1em;
  .react-datepicker__day{
    color: $color-grey-secondary;

  }
}

.react-datepicker__day--selected{
  background-color: $color-primary-color!important;
  background-image: $color-primary-color!important;
  // background-color: white !important;

}

.react-datepicker__day--selected:hover{
  background-color: $color-primary-color!important;
  background-image: $color-primary-color!important;
  // background-color: white !important;

}

.redBall{ 
  background-color: $color-primary-color!important;
  background-image: $color-primary-color!important;
  padding:5px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
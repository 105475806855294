.chat-streaming{
    margin-top: -1rem;
    height: fit-content;
    .list_chat{
        position: relative;
        left: 2rem;
        margin-left: 2rem;
    }
    #title_group{
        font-size: 18px!important;
    }
    .last_message2{
        width: 24vw;
    }
    #title_name2{
        color: grey;
    }
    #title_name2{
        font-size: 14px;
    }
    .img_user_chat2{
        height: 50px;
        width: 50px;
        position: relative;
    right: 4rem;
    top: 3rem;
    }
    .list_chat_details_streaming{
        margin-bottom: -1rem;
    }
     .chat_time{
        color: grey;
     }
    // .list_chat_details{
    //     height: 6rem;
    // }
    .box-message{
        position: relative;
        bottom: 2rem;  
    }
}

@import "../../assets/css_general/InvisionExportStyles.scss";

.profile_div .img_profile_influencer {
  @media (max-width: 768px) {
    width: 150px !important;
    height: 150px !important;
  }
  width: 100%;
  max-width: 200px;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px $color-primary-color;
  padding: 5px;
  vertical-align: middle;
}
.datos_influencer_profile {
  .RatingPoints {
    margin-bottom: 0.4rem;
    margin-top: 0rem;
  }
  margin-left: 5rem;
  @media (max-width: 768px) {
    margin-left: 8px !important;
    margin-top: 8px !important;
  }
}
.aboutme_div_influencer {
  // margin-top: -5rem;
  margin-bottom: 2rem;
}
.aboutme_influencer {
  width: 100%;
}
.profile_div {
  padding-top: 5rem;
  .grey {
    font-size: 1em;
  }
  .username_profile {
    color: $color-primary-color;
  }
  .img_profile {
    width: 17%;
    height: 79%;
  }
  .div_user {
    width: 65%;
  }
  .about_user {
    
  }
  .name_profile {
    @media (max-width: 768px) {
      font-size: 26px !important;
    }
    font-size: 2.5em;
  }
  .friends,
  .followers {
    color: black;
    font-weight: 500 !important;
    font-size: 1.3em !important;
  }

  .social-icons {
    display: flex;

    div {
      padding-right: 5px;
    }
  }
}
.showall {
  color: $color-primary-color;
}

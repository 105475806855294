.Search-div {
  width: 100%;
  position: relative;

  input {
    padding-left: 60px;
    padding-top: 27px;
    padding-bottom: 27px;
    font-size:18px;
    border-radius: 50px;
    // margin-top: 30px;

  }

  img {
    position: absolute;
    left: 20px;
    top: 16px;
  }
  @media(max-width:768px) {
    i{ 
      top: 44px;
      // left: 33px;

    }
    
  }
  i{
    font-size: 30px;
    position: absolute;
    left: 18px;
    top: 14px;
    color: #B3B8C1;
  }
}
@import "../../assets/css_general/InvisionExportStyles.scss";

.b2bLanding,
.b2bLanding .container-fluid {
  padding: 0 !important;
  background-color: $color-black-primary;
}

.b2bLanding {
  .row {
    margin: 0;
  }

  button,
  .btn,
  .bt-primary {
    background-color: $color-action-button-b2b-referral;
    border-radius: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-width: 0;
    outline-color: $color-action-button-b2b-referral !important;
  }

  p {
    font-weight: 500 !important;
  }

  .btn-primary:focus,
  .btn-primary.focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem
      $color-action-button-b2b-referral-box-shadow-focus;
  }

  .b2bEnrolHeader button {
    margin-left: 1.5%;
  }

  .action-blue {
    background-color: $color-action-button-b2b-referra-demo !important;
    outline-color: $color-action-button-b2b-referra-demo !important;
  }

  .action-blue:focus,
  .action-blue:not(:disabled):not(.disabled):active:focus {
    background-color: $color-action-button-b2b-referra-demo !important;
    outline-color: $color-action-button-b2b-referra-demo !important;
    box-shadow: 0 0 0 0.2rem
      $color-action-button-b2b-referral-demo-box-shadow-focus;
  }

  .b2bEnrolHeader p {
    color: white;
  }

  .b2bEnrolHeader {
    background-color: $color-black-primary;
    padding: 0.5vh 0;
    position: fixed;
    margin-right: 0;
    margin-left: 0;
    width: 100vw;
    z-index: 2;
  }

  .b2bEnrolHeader p {
    margin: 0;
  }

  .b2bCenterElements {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;

    picture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .b2bEnrolHeader .b2bGotmyIcon {
    height: auto;
    width: 130px;
    padding: 1rem 0;
  }

  .presentationContainer {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    max-width: 100%;
    min-height: calc(100vh - 240px);
    margin-top: 85px;

    .textContainer {
      text-align: center;
      z-index: 100;
      position: relative;
      max-width: 100%;
      margin-bottom: 2.5rem;

      h1 {
        font-size: 5rem;
        color: #fff;
      }

      p{
        font-size: 24px;
        color: #fff;
      }
    }

    .ctaButtonsContainer{
      text-align: center;
      z-index: 100;
      position: relative;
      max-width: 100%;

      .formsButtonsContainer{
        position: relative;
        display: flex;
        flex-flow: row wrap;
        -webkit-box-pack: center;
        justify-content: center;
        align-items: flex-start;
        min-width: 20%;

        .btn {
          margin: 1rem;
          min-width: 250px;
          box-shadow: none;
        }

        :nth-child(1) {
          background-color: #fe5a5e;
          border: 1px solid #fe5a5e;
          color: #fff;
        }

        :nth-child(2) {
          background-color: transparent;
          border: 1px solid #fff;
        }
      }

      .videoButtonsContainer{
        margin-top: 2.5rem;

        button {
          background-color: transparent;
          outline: none;
          font-size: 1.1rem;
          padding: 8px 10px;
          color: #fff;

          img {
            margin-right: 1rem;
          }
        }
      }
    }

    .videoHeaderContainer{
      inset: 0px;
      pointer-events: none;
      position: absolute;
      max-width: 100%;
      overflow: hidden;

      .presentationVideo{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        aspect-ratio: 16/9;
        height: 100%;
        max-width: unset;
        object-fit: cover;
      }
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0px;
      z-index: 2;
      background: rgba(0,0,0, 1);
      opacity: 0.5;
    }
  }

  .functionContainer {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin: 0;

    img{
      margin-bottom: 1rem;
      width: 100px !important;
    }

    h3 {
      color: #fff;
      text-align: center;
      font-size: 1.5rem;
    }
  }

  .modalClose {
    position: absolute;
    top: 25px;
    right: 50px;
    color: #fff;
    width: 19px;
    height: 19px;
    cursor: pointer;
    background: url(https://assets.calendly.com/assets/external/close-icon.svg) no-repeat;
    background-size: contain;
    z-index: 9999;
  }

  @media (max-width: 400px) {
    .b2bEnrolHeader .b2bHeaderIcon {
      display: none;
    }
  }

  .b2bEnrolHeader .b2bHeaderIcon {
    height: 50px;
    width: 50px;
  }

  .presentationVideoMuteControl{
    position: absolute;
    top: 5%;
    left: 5%;
    max-width: 50px;
    cursor: pointer;
  }

  .b2blandingCreateManageContainer{
    position: relative;
  }

  .b2blandingCreateManage{
    color: white;
      p{
        width: 30%;
        font-size: 1.1rem!important;
      }
  }

  .specialButton{
    background: transparent;
    width: 35%;
    background-color: #fe5a5f ;
    padding: 20px 0px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
    border-radius: 100px;
    color: white;

    &.whiteLabelPlatformButton {
      background-color: #32303e ;

      &:hover {
        color: #32303e;
        border: 1px solid #32303e;
      }
    }

    &:hover {
      background-color: transparent ;
      color: white;
      border: 1px solid white;
      transition: 0.5s;
    }
  }

  .typeEventSection {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/b2bLanding/images/SECCION2_FONDO.png");
    background-position: center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .featurestSection {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/b2bLanding/images/SECCION3_FONDO.png");
    background-position: center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .tableSection{
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/b2bLanding/images/SECCION4_FONDO.png");
    background-position: center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .testimonialVideoPreview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    video{
      object-fit: contain!important;
      cursor: pointer;
      width: 50%;
      height: auto!important;
      margin: 7px 0px;
      box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.88);
    }

    .selectedVideo{
      box-shadow: 0px 0px 7px 0px rgb(255, 255, 255);
    }
  }

  .testimonialVideoPreviewMobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

    video{
      object-fit: contain!important;
      cursor: pointer;
      width: 18%;
      height: auto!important;
      margin: 30px 15px;
      box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.88);
    }

    picture {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .selectedVideo{
      box-shadow: 0px 0px 7px 0px rgb(255, 255, 255);
    }
  }

  .testimonialSection{
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/b2bLanding/images/SECCION5_FONDO.png");
    background-position: center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .createManageIcon{
    width: 65% !important;
    padding-bottom: 30px;
    max-width: unset!important;
  }

  .createManageIconMobile{
    width: 65% !important;
    padding-bottom: 30px;
    max-width: unset!important;
  }

  .createManageIconMobileEventType{
    padding-bottom: 0px;
  }

  .backgroundImage{
    width: 100%;
    height: 100%;
    max-width: unset!important;
  }

  .abdoluteContentDiv{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    picture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .b2blandingWhitePlatform{
    p{
      width: 30%;
      font-size: 1.1rem!important;
    }
  }

  .b2bHeaderPresentation button {
    padding-left: 25px;
    font-size: 1.5rem;
    border-radius: 20px;
    padding-right: 25px;
  }

  .b2bHeaderPresentationTextContainer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    color: white;
    flex-direction: column;
  }

  .b2bHeaderPresentationTextContainer .titleContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 65%;
  }

  .b2bHeaderPresentationTextContainer .titleContainer div {
    border-width: 0px;
    border-style: solid;
    border-bottom-color: #ff002c;
    border-bottom-width: 1px;
    border-bottom-color: $color-light-primary-b2b-referral;
    margin-bottom: 15px;
  }

  .b2bHeaderPresentationTextContainer h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;
  }

  .footerTerms{
    padding: 20px !important;
    p{
      font-weight: 300!important;
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
      padding-top: 0px !important;
    }
  }

  .b2bHeaderPresentationImageContainer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }

  .leftHeaderdecorator img {
    width: auto !important;
    height: 100% !important;
    max-height: 350px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
  }

  .b2bHeaderPresentationSpecialTopDecorator {
    position: absolute;
    top: -10vw;
    left: 65%;
    width: 25vw;
    height: 25vw;
    z-index: 0;
  }

  .b2bHeaderPresentationImageContainer img {
    width: 100%;
    max-width: 350px;
    height: auto;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .whileLabelFeatures{
    p{
      color: #212529;
    }
  }

  .typeOfEventsContainer {
    padding: 5vh 0;

    h2{
      color: white;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }

    .typeEventContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;

      p{
        text-align: left!important;
      }

    }

    .typeEventContainerReasonsTouse{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .eventTypeImage{
      width: 50%;
    }

    .eventTypeImageMobile{
      width: 20%;
    }

    .eventTypeImageLaptop{
      width: 100%;
    }

    .eventTypeImageLaptopMobile{
      width: 50%;
    }

    .eventFeaturesContainer{
      color: white;
      padding: 50px;
      margin: 35px;
    }

    .eventFeaturesDivTitle{
      position: absolute;
      top: -30px;
      left: 25px;
      background-color: white;
      color: #515c68;
      padding: 10px;
      font-weight: 700!important;

      h3{
        font-weight: bold !important;
      }
    }

    .eventFeaturesDivTitleMobile{
      position: absolute;
      top: -30px;
      left: 25px;
      background-color: white;
      color: #515c68;
      padding: 10px;
      font-weight: 700!important;

      h3{
        text-align: center;
        font-weight: bold !important;
      }
    }

  }

  .b2bDescription img {
    width: 100%;
    height: auto;
    max-width: 150px;
  }

  .b2bDescriptionWhite img{
    max-width: 90px;
  }

  .b2bDescriptionItemList {
    flex-direction: column;
    padding: 0!important;
    margin: 0!important;
  }

  .b2bDescription h2 {
    text-align: center;
    text-transform: uppercase;
    width: 80%;
  }

  .b2bDescription h4 {
    font-size: 1.1rem;
    font-weight: bold !important;
    text-transform: uppercase;
    text-align: center;
    padding: 5% 0;
    width: 40%;
  }


  .b2bDescription p {
    font-size: 0.95rem;
    width: 60%;
    text-align: center;
    padding-bottom: 5%;
  }

  .b2bDescription span {
    color: white;
    -webkit-text-stroke: 1px $color-black-b2b-referral;
  }

  .b2bDescriptionWhite {
    color: white;

    .createManageIcon{
      width: 20% !important;
    }

  }

  .b2bDescriptionWhite span {
    color: #ff5a60;
    -webkit-text-stroke: 1px white;
  }

  .b2bDescriptionItemListRow {
    flex-direction: row !important;
    margin: 25px 0px;
  }

  .b2bDescriptionWhite h2 {
    padding-top: 5vh;
    width: 70%;
  }

  .b2bDescriptionItemListRow p {
    padding-bottom: 0 !important;
    width: 40%;
    margin-left: 2.5%;
    margin-bottom: 0;
    text-align: left;
  }

  .b2bTestimonial {
    padding-bottom: 5vh;
    img {
      width: 100%;
      height: auto;
      max-width: 250px;
    }

    p {
      width: 70%;
      font-style: italic;
    }

    h4 {
      font-size: 1.3em;
      font-weight: bold !important;
      text-transform: uppercase;
    }

    h5 {
      font-size: 1em;
      margin-left: 5%;
    }

    .testimonialContainer {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    @media (max-width: 400px) {
      h4,
      h5 {
        padding-left: 25px;
      }

      button {
        margin-right: 25px;
      }
    }

    @media (max-width: 768px) {
      .testimonialContainer {
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
      }

      .col-6 {
        justify-content: center;
        text-align: center;
        justify-items: center;
        align-items: center;
        align-content: center;
      }
    }
  }

  .b2bFooter {
    background-color: $color-black-b2b-referral;

    p {
      color: #696a75;
      margin: 0;
      margin-right: 15%;
      padding: 10px 0;
      text-align: right;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 200px;
      padding: 2.5% 10%;
    }
  }

  /*
  * MEDIA QUERIES
  */
  @media (min-width: 1500px) {
    .b2bHeaderPresentationTextContainer h2{
      font-size: 2.8rem;
    }
  }

  @media (max-width: 1250px) {
    .specialButton {
      font-size: 20px;
      padding: 10px 0px;
    }

    .b2bLanding .b2bDescription p {
      padding-bottom: 0;
    }
  }

  @media (max-width: 992px) {
    .headerDecorator,
    .b2bHeaderPresentationSpecialTopDecorator {
      display: none !important;
    }

    .b2bHeaderPresentationTextContainer {
      align-items: center;
      text-align: center;
    }



  }

  @media (max-width: 768px) {
    .b2bDescriptionItemListRow p {
      width: 60%;
      margin-left: 0%;
      text-align: center;
    }

    .b2bDescriptionItemListRow img {
      padding-bottom: 25px;
    }

    .b2bDescriptionItemListRow {
      flex-direction: column !important;
    }

    .b2bDescriptionWhite img{
      max-width: 150px;
    }

    .b2bDescription p {
      width: 95%;
    }
  }

  @media (max-width: 600px) {
    .b2bHeaderPresentationTextContainer .titleContainer{
      width: 80%;
    }
  }

  @media (max-width: 400px) {
    .specialButton {
      font-size: 12px;
    }

    .createManageIconMobile{
      padding-bottom: 0;
    }

    .textContainer {
      h1 {
        font-size: 3rem !important;
      }

      p{
        font-size: 18px !important;
      }
    }
  }
}

@import "../../../assets/css_general/InvisionExportStyles";


.Home-live{
  @media (max-width:768px){
    h2{ font-size: 22px !important; }
    margin-bottom: 0px !important;
  }
    h1,h2{
      text-align: left;
    }
  h1{
    color: black;
  }
  h2{
    font-weight: 600;
    font-size: 34px;
  }


}
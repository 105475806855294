@import "../../../assets/css_general/InvisionExportStyles";

footer {
  .termOfService{
    margin-bottom: 12px;
  }
  .termsOfService{
    display: flex;
    justify-content: start;
    flex-direction: row;
    @media (max-width:300px){
      flex-direction: column;
    }
  }
  #leftIcon{
    margin-right: 16px;
    @media (max-width:300px){
      margin-right: 0px;
    }
  }
  .logos{
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    @media (max-width:300px){
      flex-direction: column;
      align-items: start;

    }
  }
  .storeLogo{ 
    max-height: 43px;
    // @media (max-width:600px){
    //   max-height: 35px;
    // }
    @media (max-width:300px){
      margin-bottom: 8px;
    }
   
  }
  @media (max-width:768px) {
    margin-top:50px;
    h5{ 
      font-size: 1.1rem;
    }
    p{
      font-size:14px;
    }
  }
  margin-top: 120px;
  .Bg-blue{
    color: $color-grey-secondary;
    font-size: 16px;
    padding-top: 100px;
    padding-bottom: 200px;
    @media (max-width:768px) {
      padding-top: 50px;
      padding-bottom: 50px;
      }
  }
  .Logo {
    max-width: 200px;
    margin-bottom: 20px;

    @media (max-width:991px) {
      max-width: 150px;
      }

    @media (max-width:768px) {
      max-width: 100px;
      margin-bottom: 14px;
      }
  }

  nav {

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      padding-right: 75px;
     &:last-child{
       padding-right: 0;
     }
      li {
        padding: 0;
      }
    }


  }

}


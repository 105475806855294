.container_billing{
    padding-top: 9rem;
}
#billing{
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    .Search-div-peq{
      width: 19%;
      position: absolute;
      right: 5rem;
      margin-top: 1rem;
    }
    .select_events_stats{
      border-radius: 2rem;
      border: 1px solid #ced4da;
      padding-left: 1rem;
      height: 2.7rem;
      width: 17rem;
    }
    #select_stats_event{
    }
  }

.search {
  position: relative !important;
  width: 100% !important;
  margin-top: 3rem !important;
}


// .react-datepicker-wrapper,
// .react-datepicker__input-container,
.react-datepicker__input-container input {
  cursor: pointer;
    display: block;
    width: 100%;
    border-radius: 20px !important;
    border: 0.5px solid rgb(216, 220, 229) !important;
    color:#495057 !important;

}
.react-datepicker__header {
  border-bottom: 1px solid #aeaeae !important;
  display: none;

}
.react-datepicker__input-container input {
  padding: 8px 12px;
}
.react-datepicker__time-container{
  // padding:4px 12px;
  border: 0.5px solid rgb(216, 220, 229) !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {

  white-space: nowrap !important;
}


.react-datepicker__time-container,
.react-datepicker__time,
.react-datepicker__time-box{
  width:110px !important;
  white-space: nowrap !important;
}
.Live-Events-Page{

  .react-tagsinput--focused {
    border: 1px solid #ccc;
  }

  .react-tagsinput-tag {
    border-radius: 8px;
    background-color: white;
    border-color: #ccc;
    color: black;
  }
  .react-tagsinput { 
    height:80px;
    border-radius: 6px;
    padding: 6px;
  }
  .Search-div-peq i{
    top: -8px!important;
    font-size: 25px;
  }
  .Upcoming-live-events, .Past-live-events{
    margin-top: 2rem;
  }

  .publish_section{
    padding-top: 13rem;
  }
  .Search-div-peq{
    width: 100%;

    input{
      padding-left: 60px;
      padding-top: 23px;
      padding-bottom: 23px;
      font-size: 18px;
      border-radius: 50px;
      margin-top: -20px;
      width: 100%;

    }
 img {
      position: absolute;
      left: 20px;
      top: -8px;
    }
  }

  .Past-live-events{
    .Search-div-peq{

      position: absolute;
      width: 300px;
      right: 6%;

      input{
        padding-left: 60px;
        padding-top: 23px;
        padding-bottom: 23px;
        font-size: 18px;
        border-radius: 50px;
        margin-top: -20px;
        width: 100%;

      }
      img {
        position: absolute;
        left: 20px;
        top: -8px;
      }
    }

  }
  .Fila-live-events{
    flex-wrap: wrap;
    // .LiveEventsCard:nth-child(even){
    //     padding-left: 0;
    // }
    // .LiveEventsCard:nth-child(odd){
    //   padding-right: 0;
    // }
  }
}

input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
}

.select_category{
  -webkit-appearance: none;
  width: 100%;
  height: 3rem;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  color: grey;
  border: 1px solid #D8DCE5;
}
.input_title_video{
  padding: 0 1rem ;
  width: 100%;
  border-radius: 21px;
  border: 1px solid #D8DCE5;
  height: 3rem;
}
#time-date{
  width: 19rem;
  margin-bottom: 1rem;
}

.email_publish {
  @media (max-width:768px){
    border-top: none;

    position:static !important;
  }
  border-radius: 8px;
  border: 1px solid #D8DCE5;
  padding: 2rem;
  margin-bottom: 2rem;
  position: relative;
  right: 2rem;
  bottom: 1.5rem;
}

.date-time{
  @media (max-width:768px){
    border: none;

    position:static !important;
  }
  border-radius: 8px;
  border: 1px solid #D8DCE5;
  padding: 2rem;
  position: relative;
  right: 2rem;
  bottom: 1.5rem;
}
#input_people_publish{
  width: 9.5rem;
}
.tickets_publish{
  @media (max-width:768px){
    position:static !important;
  }

  border: 1px solid #D8DCE5;
  border-radius: 8px;
    padding: 2rem;
    position: relative;
    right: 2rem;
}
#tickets{
  width: 9.5rem;
}
.img_publish_tickets{
  position: relative;
  bottom: -0.5rem!important;
  right: 0.5rem;
  width: 30px;
  height: 30px;
}
.promo_code{
  border: 1px solid #D8DCE5;
  border-radius: 8px;
  padding: 0rem 2rem 2rem 2rem;
  position: relative;
  right: 2rem;
  top: 2rem;
}
.small_grey{
  font-size: 14px;
  color: grey;
}
.textarea_thankyou{
  padding-left: 14rem;
}
#textarea_thankyou{
  width: 75%;
  height: 6rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}
input[type=date], input[type=time]{
  text-align: center;
  padding-left: 2rem;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../../assets/img/icons/ic-addcalendar.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}
input[type=date]::-webkit-inner-spin-button, 
input[type=date]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
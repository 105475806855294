@import "../../../assets/css_general/InvisionExportStyles";


.Home-upcoming-live{
  width: 100%;
  padding: 30px 0 10px 0 !important;
    h1,h2{
      text-align: left;
    }
  h1{
    color: black;
  }
  h2{
    font-weight: 600;
    font-size: 34px;
  }


}
.next,
.prev {
  // top: calc(50% - 20px);
  top: 60px;
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 26px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}
@import "../../../assets/css_general/InvisionExportStyles.scss";


.swal2-confirm{
  background-color: $color-primary-color;
  border-left-color: $color-primary-color;
  border-right-color: $color-primary-color;
}

.modal-open{
  overflow-y:scroll !important;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #5ac8fa, #007aff, #ff2d55);
}
.Or-continue{
  color: #7A7883;
font-size: 13px;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 600;

}
.swal2-image{
  width: 20%;
}
.Toastify__toast-body{
  color:#000;
  border-radius: 2rem;
}
// input:invalid {
//   border: 1px solid red;
// }
.modal_register{
  border-radius: 3rem;
  height: 2.7rem;
}
.Modales-login {
  position: fixed;

  &.show{
      background-color: #111111!important;

  }
  .modal-open .modal{
    overflow-y: scroll !important;
    padding-right: 0;
    z-index: 1000;
  }
  p{
    font-size: 17px;
    color: black;
margin-top: 15px;
    font-weight: 600;
margin-bottom: 0px;

  }
.Login{
  color: $color-primary-color;
  display: block;
  cursor: pointer;
  font-weight: 600;
}
  .modal-dialog {
    max-width: 470px;
    margin: 1.75rem auto;
    position: relative;
    min-width: 300px;

    .modal-body {
      display: flex;
      flex-direction: column;
padding: 20px 0;
      align-items: center;

      h6{
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
      .Logo{
        width: 180px;
        margin-bottom: 30px;
      }
      .Btn-rojo{
        width: 300px!important;
        margin-top: 20px;
      }
      .Cruz {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
      }
    }




  }
}

.Botones-fl{
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a{
    color: white!important;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    display: flex;
    height: 50px;
    align-items: center;
    padding: 0;    width: 47%!important;
border-radius: 50px;
    text-align: center;
    justify-content: center;

    i{
      font-size: 25px;
      margin-right: 9px;
    }
  }

  .Btn-face{
    background-color: #3b5998;

  }
  .Btn-linkedin{
    background-color: #0e76a8;
  }
  .Btn-twitter{
    background-color: #1da1f2;
  }
}

.Modal-recover{
  .modal-body{
      padding-top: 20%!important;
      padding-bottom: 35%!important;
  }
}



.card_banned{
  box-shadow: 2px 10px 12px -3px rgba(0, 0, 0, 0.17);
  padding: 1rem;
}
.Foto_ban{
  width: 100px;
  position: relative;
  left: 0rem;
}
.user_bans{
margin-top: 25px;
.Foto{
  margin-right: 28px;
}
  .Nombre{
    font-size: 18px;
    font-weight: 600;
  }
  .foto_friend{
    border-radius: 50%;
    border: 1px solid #ff5a60;
    margin-right: 20px;
    align-self: center;
    margin-bottom: 7px;
  }
  .Icono {
    position: absolute;
    bottom: 4.2rem;
    margin-left: 1rem;
}
  .Hor {
    color: #7a7883;
    display: flex;
    font-weight: 500;
    margin-top: 4px;
    font-size: 0.9rem;

    img {
      width: 20px;
      margin-right: 5px;
      margin-left: -18px;
    }
  }
}
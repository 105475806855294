#rating_reviews .RatingPoints{
 float:right;
 margin-bottom: 2rem;
 margin-top: -5rem;
 margin-right: 1rem;
}
#rating_reviews{
    .show_reviews{
        text-align: center;
        margin-top: 1rem;
        color: #ff5a60;
        cursor: pointer;
    }
    .select_review{
        border-radius: 2rem;
        width: 12rem;
        height: 2.8rem;
        padding-left: 0.5rem;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
    .profile_stats {
        box-shadow: 0px -13px 0px 16px white, 0px -13px 0px 17px rgba(0, 0, 0, 0.125);
    }
    .stats_title{
        padding-top: 1rem;
    }
    .comments_review{
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding: 1.25rem;
        margin-top: 4rem;
    }
    .card_worst{
        h2{
            font-size: 1.5em;
        }
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding: 2rem;
        margin-top: 2rem;
    }
    .cards_rating{
        margin-top: 4rem;
    }
    .card_best{
        h2{
            font-size: 1.5em;
        }
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding: 2rem;
    }
    .title_reviews{
        font-size: 1.5em;
    }
    h3{
        font-size: 1.2em;
        margin-left: 2rem;
        padding-bottom: 1rem;
        margin-top: -2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    padding-top: 13rem;
}
.title_rating{
    padding: 0 3rem;
}
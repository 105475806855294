.container_billing{
    padding-top: 9rem;
}
#billing{
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    .Search-div-peq{
      width: 19%;
      position: absolute;
      right: 5rem;
      margin-top: 1rem;
    }
    @media screen and (max-width: 1920px) and (min-width: 1600px){
      .modal_rating {
        height: max-content!important;
    }
    .logo_invoice {
      width: 25%;
      height: 5rem;
  }
  }

    .select_events_stats{
      border-radius: 2rem;
      border: 1px solid #ced4da;
      padding-left: 1rem;
      height: 2.7rem;
      width: 17rem;
    }
    #select_stats_event{
    }
  }
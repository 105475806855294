@import "../../../assets/css_general/InvisionExportStyles";
    .rating_comment{
        color: grey;
        font-size: 15px;
    }
    .country_rating{
        width: 17px;
    }
    .Div-foto{
      margin-right: 25px;
    }
    .Foto_rating {
      border-radius: 50%;
      border: 1px solid $color-primary-color;
      margin-right: 20px;
      align-self: center;
      width: 53px;
    }
  
    .Div-foto_rating {
      position: relative;
      width: 60px;
    }
    .Icono_ra {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 20px;
    }
    .rating_and_reviews{
        font-size: 20px;
    }


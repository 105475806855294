.container__help {
  margin-top: 150px;
  margin-left: 15%;
  margin-right: 15%;

  .accordion_item {
    font-weight: bold;
    color: #ff5a60;
    font-size: 22px;
  }

  .g_color {
    color: #ff5a60;
  }

  .g_strong {
    font-weight: bold;
    color: #ff5a60;
  }

  .item_help {
    p {
      font-size: 20px;
      text-align: justify;
    }

    .tabulation_x1 {
      margin-left: 20px;
    }

    .tabulation_x2 {
      margin-left: 40px;
    }

    .strong {
      font-weight: bold !important;
    }

    table {
      margin-left: 5%;
    }

    td, th {
      padding: 8px;
    }

    th {
      background-color: #ff5a60;
      color: white;
    }
  }
}
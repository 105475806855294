@import "../../../assets/css_general/InvisionExportStyles";


.RatingPoints{
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.reviews{
  font-size: small;
  color: grey;
}
.DiaMes{
  @media(max-width:768px){

    .point{
      margin-left: 0px !important;
    }
  }
  .point{
    font-size: 56px;
    line-height: 1;
    margin-left: 6px;
    display: inline;
    color: black;
    font-weight: bold!important;
   }
  .Mes{
    font-size: 22px;
    color: $color-grey-primary;
    display: inline;
    text-align: center;
    margin-left: 7px;
  }
}
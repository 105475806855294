@import "../../../assets/css_general/InvisionExportStyles";


.Attendees{
margin-top: 25px;
.Foto{
  margin-right: 28px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  object-fit: cover;
}
  .Nombre{
    font-size: 18px;
    font-weight: 600;
    margin-left: -1rem;
  }
  .Hor {
    color: #7a7883;
    display: flex;
    font-weight: 500;
    margin-top: 4px;
    font-size: 1rem;

    img {
      width: 20px;
      margin-right: 5px;
      margin-left: -18px;
    }
  }
}
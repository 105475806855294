.parrafo_related{
    width: 65%;
}
.date_related{
    color: grey;
    margin-bottom: 0.2rem;
}
.title_related{
    font-weight: 600!important;
    margin-bottom: 0.25rem;
}
.category_related{
    color: #ff5a60;
}
.img_related{
    width: 30%;
    position: relative;
    /* bottom: 8rem; */
    left: 0rem;
    bottom: 0.5rem;
    height: 30%;
}
.Time2 {
    position: absolute;
    right: -6.3rem;
    background-color: rgba(0, 0, 0, 0.64);
    color: white;
    text-align: center;
    padding: 0px;
    border-radius: 14px;
    width: 52px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    font-size: 13px;
    margin-top: 4.3rem;
  }
@import "../../../assets/css_general/InvisionExportStyles";

.Input-with-label{
  width: 300px;
margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  label{
    font-size: 14px!important;
    color: $color-grey-primary;
font-weight: 400;
  }

  input{
    border-radius: 50px;
    border: 1px solid #b3b8c1;
    padding: 0px 20px;
    padding-top: 0px;
    height: 45px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-top: 2px;
  }

}
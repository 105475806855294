.table_category{
    position: relative;
    left: 9rem;
    bottom: 2rem;
    color: red;
    font-size: 13px;  
}
.grey{
    color:grey;
    font-size: 13px;
}
.invisible2{
    color: white;
}
.logo_invoice{
    width: 30% ;
    height: 5rem;
}
.to_influencer, th, .total{
    font-weight: bold!important;
}
.total{
    text-align: end;
}
#button_download{
    margin-left: 30%;
    margin-top: 3rem;
    padding: 1px 96px;
    height: 70px;
}
ul{
    list-style: none;
}
.first_list li{
    font-weight: bold!important;
}
.gmi{
    padding-top: 1.3rem;
}
.img_table{
    width: 8rem;
    height: 5rem;
    margin-right: 1rem;
}
.span_table{
    position: relative;
    bottom: 1.3rem;
}
.table{
    border: 1px solid #dee2e6;
}
.table thead th {
    border-bottom: unset;
}
.spacing {
    margin-top: 15px;
}

.border-bottom-grey {
    border-bottom: 1px solid rgb(110, 110, 110);
}

.icon-25 {
    width: 25px;
    height: 25px;
}

.price{
    float: right;
    font-size: larger;
}
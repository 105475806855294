@import "../../assets/css_general/InvisionExportStyles.scss";

.wellnessSection,
.wellnessSection .container-fluid {
  padding: 0 !important;
}

.wellnessSection {
  button,
  .btn,
  .bt-primary {
    background-color: $color-action-button-wellness-referral !important;
    border-radius: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-width: 0;
    outline-color: $color-action-button-wellness-referral !important;
  }

  p {
    font-weight: 500 !important;
  }

  .btn-primary:focus,
  .btn-primary.focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem
      $color-action-button-wellness-referral-box-shadow-focus;
  }

  .wellnessEnrolHeader button {
    margin-left: 1.5%;
  }

  .action-blue {
    background-color: $color-action-button-wellness-referra-demo !important;
    outline-color: $color-action-button-wellness-referra-demo !important;
  }

  .action-blue:focus,
  .action-blue:not(:disabled):not(.disabled):active:focus {
    background-color: $color-action-button-wellness-referra-demo !important;
    outline-color: $color-action-button-wellness-referra-demo !important;
    box-shadow: 0 0 0 0.2rem
      $color-action-button-wellness-referral-demo-box-shadow-focus;
  }

  .wellnessEnrolHeader p {
    color: white;
  }

  .wellnessEnrolHeader {
    background-color: $color-light-primary-wellness-referral;
    padding: 0.5vh 0;
    position: fixed;
    margin-right: 0;
    margin-left: 0;
    width: 100vw;
    z-index: 2;
  }

  .wellnessEnrolHeader p {
    margin: 0;
  }

  .wellnessCenterElements {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }

  .wellnessEnrolHeader .wellnessGotmyIcon {
    height: auto;
    width: 100%;
    padding: 10% 5%;
  }

  @media (max-width: 992px) {
    .wellnessEnrolHeader .wellnessGotmyIcon {
      height: auto;
      width: 80%;
      padding: 0% 10%;
    }
  }

  @media (max-width: 400px) {
    .wellnessEnrolHeader .wellnessHeaderIcon,
    .wellnessEnrolHeader .wellnessGotmyIcon {
      display: none;
    }
  }

  .wellnessEnrolHeader .wellnessHeaderIcon {
    height: 50px;
    width: 50px;
  }

  .wellnessHeaderPresentation {
    background-color: $color-black-wellness-referral;
    padding: 15vh 0;
    padding-top: 20vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/img/wellness/FOTOCOLOR.png");
    background-position: center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .wellnessHeaderPresentation button {
    padding-left: 25px;
    font-size: 1.5rem;
    border-radius: 20px;
    padding-right: 25px;
  }

  .wellnessHeaderPresentationTextContainer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    color: white;
    flex-direction: column;
  }

  .wellnessHeaderPresentationTextContainer p {
  }

  .wellnessHeaderPresentationTextContainer .titleContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 65%;
  }

  @media (max-width: 600px) {
    .wellnessHeaderPresentationTextContainer .titleContainer{
      width: 80%;
    }
  }

  .wellnessHeaderPresentationTextContainer .titleContainer div {
    border-width: 0px;
    border-style: solid;
    border-bottom-color: #ff002c;
    border-bottom-width: 1px;
    border-bottom-color: $color-light-primary-wellness-referral;
    margin-bottom: 15px;
  }

  .wellnessHeaderPresentationTextContainer h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;
  }

  @media (min-width: 1500px) {
    .wellnessHeaderPresentationTextContainer h2{
      font-size: 2.8rem;
    }
  }

  @media (max-width: 992px) {
    .wellnessHeaderPresentationTextContainer {
      align-items: center;
      text-align: center;
    }
  }

  .wellnessHeaderPresentationImageContainer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }

  .leftHeaderdecorator img {
    width: auto !important;
    height: 100% !important;
    max-height: 350px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
  }

  .wellnessHeaderPresentationSpecialTopDecorator {
    position: absolute;
    top: -10vw;
    left: 65%;
    width: 25vw;
    height: 25vw;
    z-index: 0;
  }

  @media (max-width: 992px) {
    .headerDecorator,
    .wellnessHeaderPresentationSpecialTopDecorator {
      display: none !important;
    }
  }

  .wellnessHeaderPresentationImageContainer img {
    width: 100%;
    max-width: 350px;
    height: auto;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .wellnessDescriptionContainer {
    padding: 5vh 0;
  }

  .wellnessDescription img {
    width: 100%;
    height: auto;
    max-width: 150px;
  }

  .wellnessDescriptionWhite img{
    max-width: 90px;
  }

  @media (max-width: 768px) {
    .wellnessDescriptionWhite img{
      max-width: 150px;
    }
    
  }

  .wellnessDescriptionItemList {
    flex-direction: column;
  }

  .wellnessDescription h2 {
    text-align: center;
    text-transform: uppercase;
    width: 80%;
  }

  .wellnessDescription h4 {
    font-size: 1.1rem;
    font-weight: bold !important;
    text-transform: uppercase;
    text-align: center;
    padding: 5% 0;
    width: 40%;
  }

  .wellnessDescription p {
    font-size: 0.95rem;
    width: 60%;
    text-align: center;
    padding-bottom: 5%;
  }

  .wellnessDescription span {
    color: white;
    -webkit-text-stroke: 1px $color-black-wellness-referral;
  }

  .wellnessDescriptionWhite {
    color: white;
    background-color: #ff5a60;
  }

  .wellnessDescriptionWhite span {
    color: #ff5a60;
    -webkit-text-stroke: 1px white;
  }

  .wellnessDescriptionItemListRow {
    flex-direction: row !important;
    margin: 25px 0px;
  }

  @media (max-width: 768px) {
    .wellnessDescriptionItemListRow {
      flex-direction: column !important;
    }
  }

  .wellnessDescriptionWhite h2 {
    padding-top: 5vh;
    width: 70%;
  }

  .wellnessDescriptionItemListRow p {
    padding-bottom: 0 !important;
    width: 40%;
    margin-left: 2.5%;
    margin-bottom: 0;
    text-align: left;
  }

  @media (max-width: 768px) {
    .wellnessDescriptionItemListRow p {
      width: 60%;
      margin-left: 0%;
      text-align: center;
    }

    .wellnessDescriptionItemListRow img {
      padding-bottom: 25px;
    }

  }

  .wellnessTestimonial {
    padding-bottom: 5vh;
    img {
      width: 100%;
      height: auto;
      max-width: 250px;
    }

    p {
      width: 70%;
      font-style: italic;
    }

    h4 {
      font-size: 1.3em;
      font-weight: bold !important;
      text-transform: uppercase;
    }

    h5 {
      font-size: 1em;
      margin-left: 5%;
    }

    .testimonialContainer {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    @media (max-width: 400px) {
      h4,
      h5 {
        padding-left: 25px;
      }

      button {
        margin-right: 25px;
      }
    }

    @media (max-width: 768px) {
      .testimonialContainer {
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
      }

      .col-6 {
        justify-content: center;
        text-align: center;
        justify-items: center;
        align-items: center;
        align-content: center;
      }
    }
  }

  .wellnessFooter {
    background-color: $color-black-wellness-referral;

    p {
      color: #696a75;
      margin: 0;
      margin-right: 15%;
      padding: 10px 0;
      text-align: right;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 200px;
      padding: 2.5% 10%;
    }
  }
}

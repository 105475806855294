@import "../../../assets/css_general/InvisionExportStyles";


.img_small .Foto {
  width: 50px!important;
}
.Div-foto_moderator{
 display: flex; 
}
.UsuPuntuacionGroup{
  position: unset;
  top: 3rem;
  right: 0rem;
  flex-direction: column;
    align-items: center;
}

.UsuPuntuacionGroup .Estrellas2 {
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
.UsuPuntuacionGroup{
  .Div-foto{
    margin-right: 25px;
  }
  .parrafo_moderator{
    color : #ff5a60;
    position: relative;
    bottom: 1.8rem;
    left: 4.5rem;
    width: fit-content;
  }
  .Attendes-div {
    position: relative;
    right: 1rem;
}
  .nombre_moderator{
    font-size: 18px!important;
    position: relative;
    right: 0rem;
    top: 0.5rem;
  }
  
  .Foto {
    border-radius: 50%;
    margin-right: 10px;
    align-self: center;
    width: 60px;
  }

  .Div-foto {
    position: relative;
    width: 60px;
  }

  .Nombre {
    font-size: 17px;
    font-weight: 600;
    display: block;
  }
  .Icono {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 20px;
  }
  .UsuPuntuacionParticipant {
    margin-left: 0rem;
}
.UsuPuntuacionParticipant .delete{
  display:none;
}
  .Estrellas{
    margin-left: auto;
    margin-right: 15px;
    img{
      width: 95px;
    }
  }
}
@import "../../assets/css_general/InvisionExportStyles";

.Not-Found-Section{
  padding: 0!important;
}

.Home-pasos{
    h1,h2,h3,h4{
      text-align: center;
    }
  h2{
    color: black;
  }
  h3{
    color: $color-grey-primary;
    line-height:46px;
    font-size: 30px;
    margin-top: 15px;

  }
  h4{
    padding-left: 35px;
    font-size:24px;
    font-weight: 600;
  }
  p{
    width: 80%;
    font-size:16px
  }
  .caja{
    position: relative;
  padding-top: 20px;
    .numero{
    position: absolute;
top: -50px;
      left: 0;
      color: $color-grey-primary;
      font-weight: 500;
      font-size: 60px;
      z-index: 1;
    }
  }
hr{
  background:$color-primary-color;
height: 4px;
  width: 30px;
  float: left;
}
}
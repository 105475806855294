@import "../../../assets/css_general/InvisionExportStyles";


.Home-discover{
  padding: 30px 0 !important;
    h1,h2{
      text-align: left;
    }
  h1{
    color: black;
  }
  h2{
    font-weight: 600;
    font-size: 30px;
    margin-bottom:35px;
  }
 
}

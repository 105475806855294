.btn-rojo {
    border-radius: 50px;
    padding: 0px 88px;
    font-size: 22px;
    height: 58px;
    border: 0;
    background-color: #ff5a60;
    color: #fff;
    position: relative;
}
.face-to-face{
    color:grey;
}

@media screen and (min-width: 1600px){
    .img_new_influencer{
        position: relative;
        left: 2rem;
    }
}
.sc-message--content.sent .sc-message--text {
    color: black;
    background-color: white;
    max-width: calc(100% - 120px);
    word-wrap: break-word;
    width: 1000px;
    max-width: 100%;
    position: relative;
    left: 4rem;
    border: 1px solid #E2E6EF;
    border-radius: 10px 10px 1px 10px;
}
.sc-header{
    display: none;
}
.sc-user-input--text:empty:before {
    content: attr(placeholder);
    display: block;
    color: grey;
    outline: none;
}
.sc-message {
    width: 347px;
}
.sc-user-input {
    min-height: 55px;
    margin: 0px;
    position: relative;
    bottom: 0;
    display: flex;
    background-color: #f4f7f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: background-color .2s ease,box-shadow .2s ease;
    width: 92%;
    border: 1px solid #B3B8C1;
    border-radius: 40px;
    background: white;
    right: 2rem;
}
.sc-message-list::-webkit-scrollbar{
    width:5px;
}
.sc-chat-window.closed {
    opacity: 1;
    visibility: unset;
    bottom: 90px;
    box-shadow: unset;
}
.sc-launcher {
    display:none;
}
.sc-chat-window {
    width: 42vw;
    height: calc(100% - 120px);
    max-height: 450px;
    position: absolute;
    right: auto;
    bottom: 100px;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
    border-radius: 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 30vw;
    // height: 1000px;
    top: 1rem;
}

.chat_demo {
    .sc-user-input {
        display: none;
    }
}
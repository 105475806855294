.pasarelas{
    .title_pasarelas{
        padding: 1rem;
    }
    .div_pasarelas{
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
    .div_p_pasarelas{
        padding-bottom: 1.5rem;
    }
    .price_transfer{
        font-size: 3em;
    }
    .input_transfer{
        width: 15rem;
        height: 2.7rem;
        border-radius: 2rem;
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding-left: 1rem;
    }
    .button_transfer{
        border-radius: 50px;
        padding: 0px 2.5rem;
        font-size: 18px;
        height: 51px;
        border: 0;
        background-color: #B4B8C1;
        color: #fff;
    }
    .all-transfer{
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding: 1rem;
        position: relative;
        left: 1rem;
        padding-top: 2rem;
    }
    .avalaible_balance{
      font-size: 1.5em;  
    }
    .adittional_advance{
        font-size: 0.8em;
        color: grey;
    }
    .change_method{
        font-size: 0.8em;
        color:#ff5a60;
        cursor: pointer;
    }
    .additional_per{
        color: grey;
    }
    .div_pasarelas{
        padding-top: 1rem;
    }
   
}
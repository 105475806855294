.confirmed{
  color: #6BC259;
}
.Live-Events-Page{

  .Search-div-peq i{
    top: -8px!important;
    font-size: 25px;
  }
  .Upcoming-live-events, .Past-live-events{
    margin-top: 2rem;
  }

  .publish_section{
    padding-top: 13rem;
  }
  .Search-div-peq{
    width: 100%;

    input{
      padding-left: 60px;
      padding-top: 23px;
      padding-bottom: 23px;
      font-size: 18px;
      border-radius: 50px;
      margin-top: -20px;
      width: 100%;

    }
 img {
      position: absolute;
      left: 20px;
      top: -8px;
    }
  }

  .Past-live-events{
    .Search-div-peq{

      position: absolute;
      width: 300px;
      right: 6%;

      input{
        padding-left: 60px;
        padding-top: 23px;
        padding-bottom: 23px;
        font-size: 18px;
        border-radius: 50px;
        margin-top: -20px;
        width: 100%;

      }
      img {
        position: absolute;
        left: 20px;
        top: -8px;
      }
    }

  }
  .Fila-live-events{
    flex-wrap: wrap;
    // .LiveEventsCard:nth-child(even){
    //     padding-left: 0;
    // }
    // .LiveEventsCard:nth-child(odd){
    //   padding-right: 0;
    // }
  }
}
input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
}
.select_category:hover{
  cursor: pointer;
}
.select_category{
  -webkit-appearance: none;
  width: 100%;
  height: 3rem;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  color: grey;
  border: 1px solid #D8DCE5;
}
.input_title_video{
  padding: 0 1rem ;

  width: 100%;
  border-radius: 21px;
  border: 1px solid #D8DCE5;
  height: 3rem;
}
#time-date{
  width: 19rem;
  margin-bottom: 1rem;
}
.date-time{
  border-radius: 8px;
  border: 1px solid #D8DCE5;
  padding: 2rem;
  position: relative;
  right: 2rem;
  bottom: 1.5rem;
}
#input_people_publish{
  width: 100%;
}
.tickets_publish{
  border: 1px solid #D8DCE5;
    padding: 2rem;
    position: relative;
    right: 2rem;
}
#tickets{
  width: 9.5rem;
}
.img_publish_tickets{
  position: relative;
  bottom: -0.5rem!important;
  right: 0.5rem;
  width: 30px;
  height: 30px;
}
.promo_code{
  border: 1px solid #D8DCE5;
  padding: 0rem 2rem 2rem 2rem;
  position: relative;
  right: 2rem;
  top: 2rem;
}
.small_grey{
  font-size: 14px;
  color: grey;
}
.textarea_thankyou{
  padding-left: 14rem;
}
#textarea_thankyou{
  width: 75%;
  height: 6rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}
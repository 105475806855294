@import "../../../assets/css_general/InvisionExportStyles";


.Attendees_moderator{
margin-top: 0px;
.FotoModerator{
  margin-right: 35px;
  width: 51px;
}
.viewerwaiting{
  width: 36px;
  border: 1px solid white;
  border-radius: 25rem;
  margin-top: 1rem;
}
#viewer1{
  z-index: 10;
  position: relative;
  left: 1.6rem;
  margin-left: -1.6rem;
}
#viewer4{
  position: relative;
  left: 0.4rem;
}
.waiting_viewers{
  position: relative;
  left: 0.5rem;
  top: 0.5rem;
}
.div_moderator_views{
  //width: 140%;
  text-align: center;
}
#viewer3{
  position: relative;
  left: 0.8rem;
  z-index: 8;
}
#viewer2{
  position: relative;
  left: 1.2rem;
  z-index: 9;
}
  .Nombre{
    font-size: 18px;
    font-weight: 600;
    //margin-left: -1rem;
  }
  .HorModerator {
    color: #ff5a60;
    display: flex;
    font-weight: 500;
    font-size: 1rem;
    //margin-left: -1rem;
    img {
      width: 20px;
      margin-right: 5px;
      margin-left: -18px;
    }
  }
}
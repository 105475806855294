@import "../../assets/css_general/InvisionExportStyles.scss";

.messages_details_private_influencer {
    input {
        border-radius: 0;
      }
      
      input[type="search"] {
        -webkit-appearance: none;
      }
    .price_information {
        border-bottom: 1px solid #D8DCE5;
        width: 280px;
        color: grey;
    }
    .details_request{
        font-size: 20px;
        @media (max-width:768px){
            font-size:16px !important;
        }
    }
    .rojo{
        color: $color-primary-color;
    }
    .request-streaming {
        float: unset; 
         position: unset; 
        bottom: 3.5rem;
        border-radius: 50px;
        padding: 0px 19px;
        font-size: 15px;
        height: 34px;
        border: 0;
        background-color: #B4B8C1;
        color: #fff;
        right: 2rem;
    }
    .works{
        color:$color-primary-color;
    }
    .request-streaming.send_request {
        background: $color-primary-color;
        margin-left: 0rem;
        padding: 0px 25px;
        margin-bottom: 1rem;
    }
}

.chatDisabled{
    color: #fff;
    text-align: center;
    margin-top: 35%;
}

.stars_request{
    float: right;
    position: relative;
    bottom: 2.5rem;
}
.some2{
    padding-left: 19px;
    padding-right: 3rem;
}
.hours{
    border-bottom: 1px solid#D8DCE5;
    width: 17.7rem;
}
.div_create_private{
    position: absolute;
    top: 13rem;
    right: -1vw;
    width: 96%;
}
.div_create_private{
     .Hor_admin{
         display: none;
     }
}
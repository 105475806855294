// .day-streaming{
//     right: 5rem;
//     top: 5rem!important;
//   }
.viewer_streaming_moderator{
    width: 100%;
    display: flex;
    align-items: self-start;
    position: relative;
    bottom: 2rem;
    left: 0.8rem;
    top: -3rem;
  }
  .message_chat{
    height: 4.5rem!important;
    // width: 231%;
    border-radius: 6px;
    position: relative;
    right: 1rem;
  }
  .moderator_row{
    padding-top: 1rem;
  }
  .Cruz{
    cursor: pointer;
  }
  .container-fluid {
    padding-left: 36px;
    @media(max-width: 768px){
      padding: 0px 12px 0px 12px;
    }
    
}
.send{
    border-radius: 50px;
    padding: 0px 34px;
    font-size: 16px;
    height: 31px;
    border: 0;
    background-color: #ff5a60;
    color: #fff;
    position: relative;
    left: 158%;
}
.caracteres_chat{
    position: relative;
    bottom: 3rem;
    right: 0.5rem;
    font-size: 13px;
    color: grey;
}
.Nav {
  @media(max-width: 768px){
    padding: 10px 16px !important;
  }
  padding: 17px 32px;
  width: 100%;
  color: white;
  // position: fixed;
  z-index: 10;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;

 
}

.Nav .Logo {
  width: 200px;
  height: 64px;
}

.Nav .Nav-menu {
  /*! margin-right: 14%; */
  width: 45%;
  margin-right: 30px;
}

// .Nav ul {
//   display: flex;
//   list-style: none;
//   padding: 0;
//   justify-content: space-evenly;
//   margin: 0;

//   height: 100%;
//   align-items: center;
// }

// .Nav ul li a,
// .Nav ul li{
//   margin-left: -2px;
//   display: flex;
//   color: white;
//   flex-direction: column;
//   margin-right: 0px;
//   font-size: 18px;
// }

// .Nav ul li span {
//   display: inline-block;
//   margin-top: 8px;
//   font-size: 16px;
// }

// .Nav ul li:first-child {
//   margin-left: 20px;
// }


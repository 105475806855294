@import "../../../assets/css_general/InvisionExportStyles";

.Menu-live-Events{
  margin:auto;
  display: flex;
  justify-content: space-around;
  width: 85%;
  padding-top: 40px;
  a{
    font-size: 18px;
    color: $color-grey-primary;
    padding-bottom: 10px;

    text-align: center;
    &.active_live_events{
      color: $color-active_link;
      border-bottom: 4px solid $color-primary-color;

      &:hover{
        cursor: initial;
      }
    }
    &:hover{
      color: white;
      cursor: pointer;
    }
  }
}
.url_irs{
    color: #ff5a60;
}
.verify{
    // padding-top: 12rem;
}
.modal_register_verifyxd{
    width: 8rem;
    border-radius: 3rem;
    height: 2.7rem;
    border: 1px solid #D8DCE5;
}
.modal_register_verify{
    width: 10rem;
    border-radius: 3rem;
    height: 2.7rem;
    border: 1px solid #D8DCE5;
}
.mobile{
    margin-top: 0.4rem;
    color: grey;
}
.country_code{
    color: grey;
}
.phone_number{
    border: 1px solid #D8DCE5;
    padding: 1rem;
}
.based{
    border: 1px solid #D8DCE5;
    padding: 1.5rem;
    margin-top: 1rem;
}
.method{
    border: 1px solid #D8DCE5;
    padding: 1rem 2rem;
    max-width: 38rem;
}
.demo input[type="radio"], .demo2 input[type="radio"],.demo3 input[type="radio"],.demo4 input[type="radio"] {
	display: none;
}
label{
    color: grey;
}
.demo input[type="radio"] + label span, .demo2 input[type="radio"] + label span,.demo3 input[type="radio"] + label span,.demo4 input[type="radio"] + label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -1px 10px 0 -5px;
    vertical-align: middle;
    border: 1px solid #B6BAC3;
    background-size: cover;
    cursor: pointer;
    border-radius: 15px;
}
.demo input[type="radio"]:checked + label span, .demo2 input[type="radio"]:checked + label span,.demo3 input[type="radio"]:checked + label span,.demo4 input[type="radio"]:checked + label span {
	background-image: url("../../../assets/img/icons/ic-check.svg");
	background-size: cover;
}

.balanced_container{
    .modal-dialog {
        max-width: 35%;
    }
    .modal-dialog .modal-body {
        padding-right: 15% !important;
        padding-left: 3rem;
    }
    .logo_modal{
       margin-left: 6rem; 
    }
}
.table {
    width: 99%;
}
.modal_content_balance{
    width: 17rem;
    border-bottom: 1px solid #dee2e6;
}
#country_balance{
    font-size: 0.8em;
    color: grey;
}
#country_balance img{
    width:15px;
    margin-right: 2px;
}
.table_balance{
    .span_table {
        bottom: 0rem;
    }
    
    .table_category {
        left: 0rem;
        bottom: 0rem;
    }
    
}
.search_div_balance{
    .Search-div-peq{
        width: 29%;
        width: 40%;
        margin-right: 7rem;
    }
    #all_transactions{
        width: 30%;
        position: relative;
        top: 3.2rem;
    }
    .select_review{
        height: 4rem;
        border-radius: 2rem;
        width: 15rem;
        padding-left: 1rem;
        position: relative;
        top: 2.6rem;
        right: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
}
@import "../../../assets/css_general/InvisionExportStyles";


.UsuPuntuacion4{
  display: flex;
  margin-left: 1rem;
  .Div-foto{
    margin-right: 25px!important;
  }
  .Foto {
    border-radius: 50% !important;
    border: 1.5px solid $color-primary-color;
    margin-right: 20px;
    align-self: center;
    width: 65px !important;
    height: 65px !important;
    object-fit: cover;
    padding:2px;
  }

  .Div-foto {
    position: relative;
    width: 60px;
  }

  .Nombre {
    font-size: 24px;
    font-weight: 500 !important;
    display: block;
  }
  .Icono {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 20px;
  }
  .Estrellas{
    margin-left: auto;
    margin-right: 15px;
    img{
      width: 100px;
    }
  }
  @media(min-width:1600px){
    .Foto{
      width:80px;
      height:80px;
      padding:3px;
    }
    .Nombre{
      font-size: 26px;
    }
    .Icono{
      right: -15px;
      width:25px
    }
  }
}
@import "../../../../assets/css_general/InvisionExportStyles";

.Card-discover {
  border-radius: 8px;
  margin-bottom: 30px;
  height: 140px;
  color: white;
  position: relative;
  span {
    position: absolute;
    left: 30px;
    bottom: 10px;
    font-size: 15px;
    display: block;
    width: 50%;
    z-index: 1;
  }
  img {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    /* -webkit-filter: brightness(80%);
    filter: brightness(80%);*/
    -webkit-filter: brightness(75%);
    filter: brightness(75%);
    // filter: contrast(120%);
  }
}
@media (max-width: 768px) {
  margin: 16px;
  .col-4 {
    padding: 0 10px !important;
  }
  .Card-discover {
    span {
      // font-size:14px !important;
      left: 0 !important;
    }
  }

  .Card-discover {
    padding: 0 8px !important;
    margin-bottom: 16px !important;
    height: auto;
  }
}
@media (min-width: 1600px) {
  .Card-discover {
    margin-bottom: 38px;
    height: 170px;
    span {
      font-size: 18px;
    }
  }
}

@import "../../../assets/css_general/InvisionExportStyles";
.Attendes-div::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.Attendes-div::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.Attendes-div::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

 /* Estilos track de scroll */
.Attendes-div::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.Attendes-div::-webkit-scrollbar-track:hover, 
.Attendes-div::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

// .Attendees{
// margin-top: 25px;
// .Foto{
//   margin-right: 28px;
// }
//   .Nombre{
//     font-size: 18px;
//     font-weight: 600;
//     margin-left: -1rem;
//   }
//   .Hor {
//     color: #7a7883;
//     display: flex;
//     font-weight: 500;
//     margin-top: 4px;
//     font-size: 0.9rem;

//     img {
//       width: 20px;
//       margin-right: 5px;
//       margin-left: -18px;
//     }
//   }
// }
.UsuPuntuacionParticipant{
  display: flex;
  margin-left: -1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .Div-foto{
    margin-right: 10px!important;
  }
  .Foto {
    border-radius: 50%;
    margin-right: 0px;
    align-self: center;
    width: 55px;
    margin-left: 0.3rem;
  }

  .Div-foto {
    position: relative;
    width: 60px;
  }

  .Nombre {
    font-size: 17px;
    font-weight: 600;
    display: block;
  }
  .Icono {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 20px;
  }
  .Estrellas{
    margin-left: auto;
    margin-right: 15px;
    img{
      width: 95px;
    }
  }
}
.Hor_admin{
  font-size: 13px;
}
.Hor_admin img{
  width:15px;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
}
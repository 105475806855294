@import "../../../assets/css_general/InvisionExportStyles";


.UsuPuntuacion2 .Estrellas2 {
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
.UsuPuntuacion2{
  display: flex;
  .Div-foto{
    margin-right: 25px;
  }
  .Foto {
    border-radius: 50%;
    border: 1px solid $color-primary-color;
    margin-right: 20px;
    align-self: center;
    width: 60px;
  }

  .Div-foto {
    position: relative;
    width: 60px;
  }

  .Nombre {
    font-size: 22px;
    font-weight: 600;
    display: block;
  }
  .Icono {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 20px;
  }
  .Estrellas{
    margin-left: auto;
    margin-right: 15px;
    img{
      width: 95px;
    }
  }
}
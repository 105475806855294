.profile_stats::after{
    content: "";
    width: 4rem;
    background: white;
    height: 10px;
    position: absolute;
    margin-left: 0rem;
    z-index: 9;
    margin-top: -0.8rem;
}
.StatsDetail{
    #canvasjs-react-chart-container-0::after {
        content: "";
        display: none;
        
    }
    .big_stadistics{
        margin-top: 8rem;
    }
    #canvasjs-react-chart-container-0 {
        box-shadow: unset;
    }
    .profile_stats_select {
        margin-top: -4.5rem;
    }
    .Back{
    margin-bottom: 2rem;
    margin-top: -1.7rem;
    margin-left: -1rem;
    }
    .price_ticket::before {
        content: "";
        width: 20rem;
        background: #D8DCE5;
        height: 1px;
        position: absolute;
        bottom: 28.5%;
        margin-left: 0.5rem;
    }
    padding-top:10rem;
}

.Search-div-messages{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
position: relative;
// bottom: 1.5rem;
input {
    padding-left: 55px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
    border-radius: 50px;
    margin-top: 0px;
}

 img {
  position: absolute;
  left: 20px;
  top: 48px;
}

  i{
    font-size: 25px;
    position: absolute;
    left: 18px;
    top: 7px;
    color: #B3B8C1;
  }
}

@import "../../../../assets/css_general/InvisionExportStyles";

.CardUpcoming {
  max-width:540px;
  display: flex;
  flex-direction: column;

  .Header {
    position: relative;

    height: 180px;
    background-size: cover;
    border-radius: 7px 7px 0 0;



    .Guardar {
      z-index: 99;
    }
    .saveCircle{
      position: absolute;
      top: 10px;
      right: 10px;

      padding: 10px;
      border-radius: 50%;
      background-color: #000;
      opacity: 60%;
      border: 0.5px solid #949494 ;
    }

    @media (min-width: 1600px) {
      .saveCircle {
        padding: 15px;
      }
      
    }
  }

  .Negro {
    @media (min-width: 1600px) {
     padding:10px; 
     .Foto{
      height: 55px !important;
      width: 55px !important;
     } 
    }
    display: flex;
    padding: 8px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    color:white;
    width: 100%;
    .Foto {
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid $color-primary-color;
      margin-right: 20px;
      align-self: center;
      height: 50px ;
      width: 50px ;
    }

    .Div-foto {
      position: relative;
    }

    .Icono {
      position: absolute;
      bottom: 16px;
      right: 18px;
      width: 20px;
    }

    .Nombre {
      font-size: 18px;
      display: block;
    }

    .Pais {
      color: $color-grey-primary;
      font-size: 13px;
      display: flex;

      img {
        margin-right: 6px;
        width: 15px;
      }
    }

    .Estrellas{
      margin-left: auto;
      margin-right: 15px;
      img{
        width: 75px;
      }
    }
  }
  .Div-asis{
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: center;
    text-align: end;
    margin-left: auto;
    margin-right: 5px;
  }
  .Asistentes{
    font-size: 19px;
    display: flex;
    justify-content: flex-end;
    img{
      width: 17px;
      margin-right: 5px;
    }

  }
  .Tickets{
    font-size: 13px;
    font-weight: 600;
  }

  .Footer {
    @media(max-width:768px){
      .Tit{
        font-size: 16px;
          font-weight: 500 !important;
          padding-top: 5px;
          color:black;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          
        }
        .Texto{
          margin-left: 15px;
        }
    }
    letter-spacing: 0.025em;
    display: flex;
    padding:6px;
      .Texto{
        margin-left: 25px;
        display: flex;
        flex-direction: column;

      .Tit{
      font-size: 20px;
        font-weight: 500 !important;
        padding-top: 5px;
        color:black;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 250px;
        
      }
      .Cat{
        color: $color-primary-color;
        font-size: 14px;
        font-weight: 500;
        @media (min-width: 1600px) {
          font-size:16px;
          
        }
      }
      .Hor{

        font-size:15px;
        color: $color-grey-primary;
        display:flex;
        font-weight: 500;
        margin-top: 4px;
        img {
          margin-top:-2px;
          margin-right: 6px;
          width: 17px;
        }
      }
    }
    .Fecha{
      display: flex;
      margin-top:-4px;
      flex-direction: column;
      justify-content: start;
      margin-left: 5px;
      .Dia{
        font-size: 30px;
        font-weight: 500 !important;
        color:black;
      }
      .Mes{
        font-weight: 500 !important;
        margin-top:-8px;
        color: $color-primary-color;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    .Pais {
      color: $color-grey-primary;
      font-size: 13px;
      display: flex;

      img {
        margin-right: 6px;
        width: 10px;
      }
    }
    @media (min-width: 1600px) {
      padding:8px;
      .Fecha{
        .Mes{
          margin-top: -4px;
        }
        .Dia{
          font-size: 34px;
        }
      }
      .Texto{ 
        .Tit{font-size:22px;}
      .Cat{font-size:16px;}
      .Hor {
        font-size: 18px;
      }
     
    }
    }
  }

}
@media (min-width: 992px) and (max-width: 1250px) {
  .CardUpcoming {
    .Footer {
      .Hor{
        color: $color-grey-primary;
        display:flex;
        font-weight: 500;
        margin-top: 4px;
        width: 90%;
        img {
          margin-right: 6px;
          width: 17px;
        }
      }
    }
  }
}
@media (min-width: 1600px) {
  .CardUpcoming{

    .Header{
      height:225px;


    }
   
  }

  }
@media (min-width: 1600px) {
  
  }
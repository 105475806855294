@import "../../../assets/css_general/InvisionExportStyles";

.videoBg {
  right: 0;
  bottom: 0;
  height: 400px;

}
.Home-header {
  @media  (max-width: 768px ) {
    h2{
      font-size: 28px;
    }
    .Contenido {
      padding: 0 12px; 
      width: 100% !important;
    }
    margin-top: 0px !important;
  }
  background:  linear-gradient(
                  rgba(12, 08, 36, 0.45),
                  rgba(12, 08, 36, 0.45)
   ),url("../../../assets/img/bg-homr.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // margin-top: 97px;
  position: relative;
  color:white;
  text-align: center;
padding: 100px 0 !important;
  h2{
    font-size: 32px;
  }
  .Ico-play{
    position: absolute;
    bottom:40px;
    left: 40px;
  }
  .Contenido {
    width: 50%;
    margin: 0 auto;
    @media (max-width:768px){
      h1{ 
        font-size: 46px !important;
        margin-bottom:14px !important;
      }
    }
    h1{
      font-size: 58px;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
    }
    .Search-div {
      width: 100%;
      position: relative;

      input {
        padding-left: 60px;
        padding-top: 27px;
        padding-bottom: 27px;
        font-size:20px;
        border-radius: 50px;
        // margin-top: 30px;

      }

      img {
        position: absolute;
        left: 20px;
        top: 16px;
      }
    }
  }
}
section {
  @media (max-width:768px){
    padding: 0px !important;
  }
  padding: 20px 0 !important;
  &.homeVideoHeader {
    padding:0px !important;
    position: relative;
    color:white;
    text-align: center;
    padding-top: 80px;
    h2{
      font-size: 28px;
    }
    &::before {
      content: '';
      background: #0E102B;
      opacity: 0.8;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0px;
      left: 0;
      right: 0;
      @media (max-width:768px){
        bottom:0px;
      }
    }
    
    .fullscreen-bg__video {
      width: 100%;
      height: auto;
      // min-width: 100%; 
      // min-height: 100%; 
      // width: auto;
      // height: auto;
      // width:100vw;
      // height: 100vh;
    }
    .Contenido {
      @media( max-width: 768px){
        h1{ 
          font-size: 24px !important;
        }
        h2{ 
          margin-top:12px;
          font-size:20px !important;
        }
      }
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      //z-index: 1;
      top: 0;
      bottom: 0;
  
      h1{
        margin-bottom: 0;
      }
  
      .Search-div {
        @media(max-width: 768px){
          width:100%;
          padding:16px;
        }
        width: 50%;
        align-self: center;
        position: relative;
  
  
        input {
          padding-left: 60px;
          padding-top: 27px;
          padding-bottom: 27px;
          font-size:18px;
          border-radius: 50px;
          margin-top: 0;
  
        }
  
        img {
          position: absolute;
          left: 20px;
          top: 16px;
        }
      }
    }
  }
} 
@import "../../../assets/css_general/InvisionExportStyles";


.NombreCat{
  position: relative;
  bottom: 0.7rem;
  left: 1rem;
  h1{
    font-size: 2.5rem;
    font-weight: bold !important;
  }

  h2{
    color: #ff5a60;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .Hor{
    color: #7a7883;
    display: flex;
    font-weight: 500;
    margin-top: 4px;
    font-size: 1rem;
    img{
      width: 15px;
      margin-right: 5px ;
    }
  }

  

}
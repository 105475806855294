@import "../../../assets/css_general/InvisionExportStyles";


.FechaCat2{
  box-shadow: 1px 3px 1px 1px #373445;
}
.FechaCat{
  background: $color-black-primary;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 0.5rem 1rem 1rem 1rem;
}
.Logo2{
  width: 12%;
}
.leave-streaming{
  float: right;
  position: relative;
  bottom: 5rem;
  border-radius: 50px;
  padding: 0px 16px;
  font-size: 18px;
  height: 32px;
  border: 0;
  background-color: #908E99;
  color: #fff;
  right:2rem;
}
.NombreCat{

  h1{
    font-size: 2.5rem;
    font-weight: 600;
  }

  h2{
    color: #ff5a60;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .Hor{
    color: #7a7883;
    display: flex;
    font-weight: 500;
    margin-top: 4px;
    font-size: 1rem;
    img{
      width: 15px;
      margin-right: 5px ;
    }
  }


}
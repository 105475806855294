.div_logout{
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 18px;
}
.boton_go{
  padding: 0 48px!important;
}
.dropdown-item:hover{
  background: unset!important;
  font-weight: 600 !important;
}
 .LogOut{
   color: #ff5a60!important;
   cursor: pointer;
 }
 .dropdown-toggle::after {
   display: none;
 }
 .profile::before {
  content: "";
  position: absolute;
  top: -13px;
  left: 73%;
  width: 0;
  height: 0;
  border-width: 0 14px 17px;
  border-style: solid;
  border-color: transparent transparent white;
}
 .profile{
   top: 14px!important;
   left: -5rem !important;
   border-radius: 14px;
   @media(max-width: 450px){
     left: -4.5rem !important;
   }
 }
 .dropdown-item {
   padding: 1.25rem 4rem;
   position: relative;
   right: 1rem;
   @media (max-width:768px){
     padding: 0.75rem 2.5rem !important;
   }
 }
 .dropdown-item img {
   position: relative;
   right: 1rem;
 }

.Logued-div {
  @media(max-width: 768px){
    img {
      width:35px !important;
      height: 35px !important;
    }
  }
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  
  img {
    margin-left: 15px;
    border: 1px solid white;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    object-fit: cover;
    @media(max-width: 768px){
      margin-left: 10px !important;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: right;

    .Name {
      color: white;
      font-size: 21px;
      @media(max-width: 768px){
        font-size:18px !important;
      }
    }

    .Mode {
      color: #7A7883;
      font-size: 17px;
      @media(max-width: 768px){
        font-size:15px !important;
      }
    }


  }
}
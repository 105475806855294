@import "../../../../assets/css_general/InvisionExportStyles";


.link_past{
  width: 25%;
}
.CardVideo {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  // max-width: 114%;
  // width: 120%;
  margin-bottom: 7%;
  .Shadow {
    border-radius: 7px;
    //margin-right: 2rem;
  }

  .Header {
    position: relative;

    height: 150px;

    background: linear-gradient(
                    rgba(17, 16, 22, 0.85),
                    rgba(17, 16, 22, 0.85)
    );
    background-size: cover;
    border-radius: 7px 7px 0 0;


    .Time {
      position: absolute;

      bottom: 5px;

      right: 5px;

      background-color: rgba(0, 0, 0, 0.64);

      color: white;

      text-align: center;

      padding: 0px;

      border-radius: 14px;

      width: 52px;

      font-weight: 600;

      display: flex;

      justify-content: center;

      align-items: center;

      padding-bottom: 2px;

      font-size: 13px;
    }

  }

  .Div-asis {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: center;
    text-align: end;
    margin-left: auto;
    margin-right: 5px;
  }

  .Asistentes {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;

    img {
      width: 17px;
      margin-right: 5px;
    }

  }

  .Tickets {
    font-size: 13px;
  }

  .Footer {
    padding: 10px;

    padding-bottom: 30px;




    .Pais {
      color: $color-grey-primary;
      font-size: 13px;
      display: flex;

      img {
        margin-right: 6px;
        width: 10px;
      }
    }

  }

  .Negro {
    display: flex;
    background-color: white;
    bottom: 0;
    color: black;
    width: 100%;

    .Foto {
      border-radius: 50%;
      border: 1px solid $color-primary-color;
      margin-right: 20px;
      align-self: center;
      width: 50px;
      height: 50px;
    }

    .Div-foto {
      position: relative;
      margin-right: 0;
    }

    .Icono {
      position: absolute;
      bottom: 16px;
      right: 18px;
      width: 20px;
    }

    .Nombre {
      font-size: 19px;
      display: block;
    }


    .Pais {
      color: $color-grey-primary;
      font-size: 13px;
      display: flex;

      img {
        margin-right: 6px;
        width: 15px;
      }
    }

    .Estrellas {
      margin-left: auto;
      margin-right: 15px;

      img {
        width: 75px;
      }
    }
  }

  .Titulo {
    font-size: 19px;
    display: block;
    margin-top: 15px;
    font-weight: 600;
    color:black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 230px;
  }

  .Cat {
    color: $color-primary-color;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
    margin-top:  0px;
  } .Fecha {
      color: $color-grey-primary;
      font-size: 14px;
      font-weight: 500;
    }
}
@import "../../../assets/css_general/InvisionExportStyles";



.Hor3{
  float: right;
  position: relative;
  bottom: 2rem;
  left: 8rem;
  color: #fff;
  font-size: 12px;
}
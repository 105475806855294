.Nav {
  padding: 17px 32px;
  @media(max-width: 768px){
    padding: 10px 16px !important;
  }
  width: 100%;
  color: white;
  // position: fixed;
  z-index: 10;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Nav-non-signing {
  justify-content: center !important;
}

.fixed{
  position: absolute;
  left: 1.75%;
}

.Nav .Logo {
  width: 200px;
  height: 64px; 
}

.Nav .Nav-menu {
  width: 45%;
  margin-right: 30px;

  
  @media(max-width:920px){
    max-width: 200px;
  }
  
}

.Nav ul {
  @media(min-width:768px){
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-evenly;
    margin: 0;
  
    height: 100%;
    align-items: center;

  }

}

.Nav ul li a,
.Nav ul li{
  @media(min-width: 768px){

    margin-left: -2px;
    display: flex;
    color: white;
    flex-direction: column;
    margin-right: 0px;
    font-size: 18px;
  }
 
}

// .Nav ul li span {
//   display: inline-block;
//   margin-top: 8px;
//   font-size: 16px;
// }

.Nav ul li:first-child {
  @media(min-width:768px){
    margin-left: 20px;

  }
}

@media screen and (max-width: 768px){
  .Nav .Logo {
    width: 100px !important;
    height: 33px !important;
  }
  
  .Nav {
    padding: 6px 10px !important;
    width: 100%;
    color: white;
    
  
   
  }
}
.Home-upcoming-live{
  padding: 5px 0;
}
.pitch_publish{
  position: relative;
  padding-top: 11rem;
}
.react-html5-camera-photo>img, .react-html5-camera-photo>video {
  //width: 768px;
  background: #000A13;
}
#inner-circle {
  background: #FF5A60!important;
  cursor: pointer;
}
.display-error{
  display: none;
}
.pitch-h2{
  font-size: 23px;
  padding: 0 14rem;
}
.pitch-p{
  color: grey;
  padding: 0 20rem 0 14rem;
}
#live_event_stats{
  padding-top: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  .Search-div-peq{
    width: 19%;
    position: absolute;
    right: 5rem;
    margin-top: 1rem;
  }
  .select_events_stats{
    border-radius: 2rem;
    border: 1px solid #ced4da;
    padding-left: 1rem;
    height: 2.7rem;
    width: 17rem;
  }
  #select_stats_event{
  }
}
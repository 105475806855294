.information::before{
  content: "";
  width: 170%;
  height: 2px;
  background: #D8DCE5;;
  display: block;
  position: relative;
  bottom: 1rem;
}
.price_information{
  border-bottom: 1px solid #D8DCE5;
  min-width: 175px;
  @media (min-width: 1200px){
    width: 300px;
  }
  max-width: 300px;
  color: grey;
}
.information_detalles img{
  position: relative;
  bottom: 0.5rem;
  right: 0.5rem;
  width: 30px;
}
.information{
  color: black;
  font-size: 19px;
  font-weight: 500!important;
}
.past_chat_event{
  margin-left: 1rem;
  position: relative;
  bottom: 1rem;
  @media (max-width: 768px){
    margin-left: 0px;
  }
}

.Live-Event-Detail .Mb {
  margin-bottom: 0rem!important;
}
.UsuPuntuacion {
  margin-top: 2rem;
}
.relate-div{
  overflow: hidden;
    width: 150%;
    height: auto;
}
.NombreCat{
  position: relative;
  bottom: 0.7rem;
}
.NombreCat h1{
  margin-bottom: 0;
}
.past{
  margin-left: 3rem;
  padding-right: 3rem;
}
.viewers-past{
  display: flex;
  align-items: self-end;
  margin-top: 1rem;
  position: relative;
  top: 1rem;
}
.box-streaming{
  position: relative;
  display: flex;
  align-items: center;
  top: 1.5rem;
  background: rgba(0, 0, 0, 0.5);
  height: 75px;
  border-radius: 15px 15px 0 0;
}
.img-upcoming{
  margin-top: -3.1rem!important;
}
.start{
  position: relative;
    left: 1.5rem;
    color: #fff;
    font-size: 18px;
    top: 0.5rem;
}
.hotspot{
  position: relative;
  border-radius: 50px;
  padding: 0px 17px;
  font-size: 18px;
  height: 49px;
  border: 0;
  background-color: #ff5a60;
  color: #fff;
  left: 15rem;
}
.upcoming-details{
  // padding-top: 3rem;
}
@media(max-width:768px){
  .upcoming-details{
    padding-top:0px;
  }
}

.Live-Event-Detail{
  margin-top: 97px;
  position: relative;
  padding-top: 25px;
  padding-bottom: 0;
  i{
    font-size: 30px;
  }
  .Mb{
      margin-bottom: 2rem;

  }

  .D-title{
    font-size: 22px;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 5px;font-weight: 600;
  }
  .Desc{
    font-size: 16px;
    margin-top: 5px;
font-weight: 600;
    padding: 5px;
  }
}
//xd
.div_private_information{
  width: 150%;
  max-width: 150%;
}
@media screen and (min-width: 1600px){
  .past_chat_event {
    padding-left: 5rem;
}
}
#cancel_live_event, #edit_live_event{
  float: unset;
  position: relative;
  bottom: -0.5rem;
  right: 1rem;
}
.politic_div{
  @media(max-width: 768px){
    margin-left:0px;
  }
  margin-left: 5rem;
}
.parrafo_politicas{
  padding: 0 8rem 0rem 0;
  color: grey;
  width: 45rem;
}
.information::before {
  content: "";
  width: 140%;
  height: 1px;
  background: #D8DCE5;
  display: block;
  position: absolute;
   bottom: unset; 
  margin-top: -2rem;
}
#img_before_start{
  margin-top: -3.1rem;
}
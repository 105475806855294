@import "../../assets/css_general/InvisionExportStyles.scss";

.streamingContainer{
}

.mediaPlayerChatContainer {
  
}

.mediaPlayer{

}

.streamingLayout{
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: center;
  min-height: 75vh;
}

#my-player{
  min-height: 75vh!important;
  background-color: black;
  display: flex!important;
  align-content: center;
  align-items: center;
  justify-content: center;
  outline: none!important;
}

#my-player button{
  outline: none!important;
}

#my-player video{
  width: 100%;
  height: 100%;

  
}

.vjs-big-play-button{
  position: relative!important;
  align-items: center;
  justify-content: center;
  border-radius: 50%!important;
  width: 3.5em!important;
  height: 3.5em!important;
  border-color: #ff5a60!important
}

.vjs-big-play-button .vjs-icon-placeholder {
  text-align: center;
  display: flex;
  font-size: 2.5em!important;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
}

.vjs-big-play-button .vjs-icon-placeholder:before{
  position: relative!important;
  display: flex!important;
  align-self: center!important;
  justify-self: center!important;
  width: auto!important;
  height: auto!important;
  outline: none!important;
}

.messageContainerViewerRTMP{
  min-height: 75vh!important;
}

.vjs-poster{
  background-size: cover!important;
}

.vjs-volume-level,
.vjs-play-progress,
.vjs-slider-bar {
  background: #ff5a60!important;
}

.video-js .vjs-control-bar{
  background: #0000009e!important;
}

.vjs-error-display{
  display: none!important;
}

.loaderStreamingModal{
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.streamingSideBarContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0!important;
  //top: 0;
}

.streamingSideBarContainer .pro-sidebar > .pro-sidebar-inner{
  background-color: $color-black-primary;
}

.rtf--mb{
  outline: none!important;
}

.viewersContainerRTMP{
  margin-left: 2vw!important;
  margin-top: 1vh!important;
}
@import "../../../../assets/css_general/InvisionExportStyles";
.stars{
  height: 14px;
  margin-left: auto;
}
.precio_total{
  color: #000;
  font-size: 26px;
}
.price_ticket::before{
  // content: "";
  width: 18rem;
  background: #D8DCE5;
  height: 2px;
  position: absolute;
  bottom: 29%;
  // margin-left: 2.5rem;

}
.viewers-influencer{
  position: relative;
  top: 0.4rem;
  color: grey;
}
.price_ticket p:first-child{
  color:#000;
  margin-bottom:0;
}
.price_ticket p:last-child{
  color:grey;
  font-size:13px;
}
.Div-foto {
  margin-right: 0px;
}

.LiveEventsCard{
  .Header {
    // background: url('../../../../assets/img/image.png')!important;
    background-size: cover!important;
    background-repeat: no-repeat;
  }
  .Start{
    position: absolute;
    left: 20px;
    top: 20px;
    color: white;
    font-size: 16px;
    border-radius: 15px;
    padding: 3px 13px;
    background-color: $color-primary-color;
}
}
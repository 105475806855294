.Home-upcoming-live{
  padding: 5px 0;
}
.pitch_publish{
  position: relative;
  padding-top: 13rem!important;
}
.react-html5-camera-photo>img, .react-html5-camera-photo>video {
  //width: 768px;
  background: #000A13;
}
#inner-circle {
  background: #FF5A60!important;
  cursor: pointer;
}
.display-error{
  display: none;
}
.pitch-h2{
  font-size: 23px;
  padding: 0 14rem;
}
.pitch-p{
  color: grey;
  padding: 0 20rem 0 14rem;
}

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-weight: 500;
  src: local("CircularStd"), url(./assets/fonts/CircularStd-Medium.otf) format("opentype");
}
@font-face {
  font-family: "FuturaStd";
  font-style: normal;
  font-weight: 500;
  src: local("FuturaStd"), url(./assets/fonts/FuturaStd-CondensedBold.otf) format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.modal {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
#influencer-congrats {
  position: absolute;
  background-color: rgba(0,0,0,.8);
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  z-index: 999999;
}
#influencer-congrats div {
  background-color: white;
  padding:1.5em;
  position:fixed;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  border-radius:1em;
}
#influencer-congrats span {
  border-radius:50%;
  background-color: #ff5a60;
  width:1em;
  height:1em;
  position:absolute;
  top:.5em;
  left:calc(100% - 1.5em);
  cursor:pointer;
}
#influencer-congrats span::before,#influencer-congrats span::after{
  content:'';
  height:70%;
  width:.2em;
  background-color:white;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
}
#influencer-congrats span::after{
  transform:translate(-50%,-50%) rotate(-45deg);
}


.Btn-instagram{
  /*background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);*/
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
/*
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;*/
}


@import "InvisionExportStyles";
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

button:focus{
  outline: none;
}

:export {
  mainColor: $color-primary-color;
  secondColor: $color-secondary-color;
  mainBackground: $color-black-primary;
  loaderColor: $color-loader-color;
}

.text-danger{
  color: $color-primary-color!important;
  background-image: $color-primary-color!important;
}

a:hover {
  color: $color-secondary-color !important;
  color: $color-primary-color !important;
  //background: $color-primary-color;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}

.CookieConsent{
  color: $color-cookiesText-color !important;
}

.Toastify__progress-bar {
  background: $color-primary-color!important;
}

footer a:hover, header a:hover, .Nav a:hover, .Modal a:hover, .modal a:hover, .eventsMenu a:hover, .Desc a:hover, .social-icons a:hover, .influencerAboutMeDescription a:hover{
  color: $color-primary-color !important;
  //background: $color-primary-color;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}

.BoxStreaming a:hover, .UsuPuntuacion4 a:hover, .privateInfoContainer a:hover, .userNavMenu a:hover{
  color: $color-secondary-color !important;
  background: transparent;
  -webkit-text-fill-color: unset!important;
}

.generalLoader {
  color: $color-primary-color !important;
  background: $color-primary-color;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $color-scroll-bar-background;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-scroll-bar-hover;
}

::-webkit-scrollbar-thumb {
  background: $color-primary-color;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border-radius: 20px;
}

.nav {
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
  flex-wrap: nowrap !important;
}
.dropdown-menu {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}
.nav-tabs .nav-link {
  @media (max-width: 768px) {
    margin-right: 12px !important;
  }
  // margin-right: auto !important;
}
*:not(i, span, h2) {
  font-weight: 400 !important;
}
h1 {
  font-size: 52px;
}
h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 35px;
}
textarea {
  resize: none;
}
// body{
//   position:fixed;
// }
.h-15 {
  height: 15px;
}

section {
  padding: 50px 0;
}
/*COLORES*/
.Bg-red {
  background-color: $color-primary-color;
}
.Bg-black {
  background-color: $color-black-primary;
}
.Bg-blue {
  background-color: $color-black-primary;
}
.Bg-grey {
  background-color: $color-grey-primary;
}
.Bg-grey-l {
  background-color: $color-grey-secondary;
}
.Font-peq {
  font-size: $font-peq;
}

.Cg {
  color: $color-grey-primary;
}
/*SHADOW*/
.Shadow {
  -webkit-box-shadow: 6px 10px 24px 6px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 6px 10px 24px 6px rgba(0, 0, 0, 0.17);
  box-shadow: 6px 10px 24px 6px rgba(0, 0, 0, 0.17);
}
.Shadow-base {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.Shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.Shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.Shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.rounded-md {
  border-radius: 0.375rem;
}

#rcc-confirm-button{
  background-color: $color-primary-color !important;
  background-image: $color-primary-color !important;
}

.Btn-rojo {
  @media (max-width: 768) {
    padding: 6px 28px;
    border-radius: 25px !important;
    height: auto;
  }
  border-radius: 50px;
  padding: 0px 28px;
  font-size: 18px;
  height: 51px;
  border: 0;
  background-color: $color-primary-color;
  background-image: $color-primary-color;
  color: #fff;
}

.Btn-green {
  background-color: #70ad47 !important;
}

.Btn-white {
  @media (max-width: 768) {
    padding: 6px 28px;
    border-radius: 25px !important;
    height: auto;
  }
  border-radius: 50px;
  padding: 0px 28px;
  font-size: 18px;
  height: 51px;
  background-color: $color-black-primary;
  border: 10;
  border-color: $color-black-primary;
  background-color: #ffff;
  color: $color-black-primary;
}

.Btn-paypal {
  @media (max-width: 768) {
    padding: 6px 28px;
    border-radius: 25px !important;
    height: auto;
  }
  border-radius: 10px;
  padding: 0px 28px;
  font-size: 18px;
  height: 51px;
  border: 0;
  background-color: $paypal_color;
  color: #fff;
}
.Btn-rojo-join {
  border-radius: 50px;
  padding: 0px 28px;
  font-size: 18px;
  height: 51px;
  border: 0;
  background-color: $color-primary-color;
  color: #fff;
  @media (max-width: 768px) {
    border-radius: 20px !important;
    padding: 0px 10px !important;
    font-size: 16px !important;
    // height: 30px;
  }
}
.Btn-rojo-follow-influencer {
  border-radius: 50px;
  padding: 0px 28px;
  font-size: 18px;
  height: 40px;
  border: 10;
  border-color: black;
  background-color: #ffff;
  color: black;
}
.P-relative {
  position: relative;
}
.Input-linea {
  border: 0;
  // border-bottom: 1px solid $color-grey-secondary;
  border-bottom: 1px solid #f4f2f2;
  padding-bottom: 4px;
  color: $color-grey-primary;
  font-size: 17px;
}
.Mr-32 {
  margin-right: 32%;
}
.Scroll-y-a {
  overflow-y: scroll;
}
.Attendes-div {
  overflow-y: scroll;
  height: 600px;
}

.buy-btn{
  background-color: $color-action-button!important;
  background-image: unset !important;
}

.Btn-rojo-b {
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    height: 70px;
    font-size: 22px;
    border-radius: 5px;
    position: sticky;
    left: 0;
    bottom: 0;
  }
  border-radius: 50px;
  width: 100%;
  font-size: 18px;
  height: 55px;
  border: 0;
  background-color: $color-primary-color;
  background-image: $color-primary-color;
  color: #fff;
}
.paragraph {
  font-size: 1.4rem;
  font-weight: 500;
}
.Btn-rojo-peq {
  width: 250px;
  border-radius: 50px;
  padding: 10px 50px;
  font-size: 17px;
  height: 50px;
  border: 0;
  background-color: $color-primary-color;
  color: #fff;
}
.Show-all {
  @media (max-width: 768px) {
    top: 0;
  }
  color: $color-primary-color;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  //width: 88px;
  width: auto;

  margin-left: auto;

  align-items: flex-end;

  padding-bottom: 0px;
  //  position: absolute;

  right: 15px;

  top: 9px;
  img {
    margin-bottom: 2px;
    width: 20px;
  }
}

.br-10 {
  border-radius: 10px;
}
.outerWrapper {
  overflow-y: hidden !important;
}
.Back {
  color: $color-primary-color;
  font-size: 16px;
  display: flex;
  font-weight: 600;
  width: 100%;

  margin-left: auto;

  align-items: center;

  padding-bottom: 0px;

  img {
    width: 20px;
    margin-right: 7px;
    margin-top: 2px;
  }
}

.mw-50 {
  max-width: 50% !important;
}

.mw-70 {
  max-width: 70% !important;
}

.mw-100 {
  max-width: 100% !important;
}

a {
  color: $color-primary-color;
  &:hover {
    color: $color-primary-color;
    text-decoration: none;
    font-weight: 400;
  }
}

/*----------------
APP.js cookies banner
--------------*/

.gotmy-title-cookies-banner {
  color: $color-cookiesSpecial-color!important;
  font-weight: bold !important;
}

/*----------------
MODIFICACION COMPONENTE REACT SELECT
--------------*/
.css-yk16xz-control {
  border-radius: 25px !important;
  padding-left: 12px !important;
}
.css-tj5bde-Svg {
  width: 30px !important;
  height: 25px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-1pahdxg-control {
  border-color: hsl(0, 0%, 80%) !important;
}
.css-1laao21-a11yText {
  border-color: hsl(0, 0%, 80%) !important;
}
// input[type="time"]::-webkit-calendar-picker-indicator {
//   background: none;
// }
// EFFECTS

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.05);
}

.textArea {
  padding: 12px;
  border-radius: 8px;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

/*-------------
  END
  ----------*/

@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
  .col-12 {
    padding: 0 0;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .container {
    max-width: 1300px;
  }
}

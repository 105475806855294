
@media(max-width:991px){
    .profile_div .about_user{
        margin-left: 0 !important;
    }
}


.profile_div{
    padding-top: 5rem;
    @media(max-width:768px){
        padding-top:1.5rem !important;

        
    }
    .grey{
        font-size: 1em;
    }
    .username_profile{
        color: #ff5a60;
        font-size: 1.3rem;
    }
    .img_profile{
        width: 160px !important;
        border: 1px solid #ff5a60;
        height: 160px !important;
        object-fit: cover;
        border-radius: 50%;
        vertical-align: middle;
 @media (max-width:768px){
    width: 120px !important;;
    height: 120px !important;;

 }
        @media (min-width: 1600px) {
            width: 180px !important;;
            height: 180px !important;;
          }
    }
    .div_user{
        width: 70%;
        @media (max-width: 1200) {
            width: 100%;
            }
    }
    .about_user{
        
    }
    .name_profile{
        font-size: 2.5em;
    }
    .friends, .followers{
        color: black;
        font-weight: 500!important;
        font-size: 1.3em!important;

    }

    .social-icons {
        display: flex;

        div {
            padding-right: 5px;
        }
    }
    
}
.showall{
    color: #ff5a60;
}

.about_div {

    h5{
        font-size:1.6rem;
        margin-bottom: 8px;
    }
    #about{
        font-size: 1.1rem;
        margin-top:12px;
        font-weight: 500 !important;
    }
}

.user_profile{ 
    padding-right: 3rem;
    padding-left: 2.5rem;
    @media(max-width: 768px){
        padding: 0rem 0.5rem !important;
    }
}
.subsectionTitle{
    @media(max-width: 768px){
        font-size: 22px;
    }
}
.right_side_profile{ 
    @media(max-width: 768px){
        padding: 0px !important;
    }
    width: 30%;
    padding-left: 3rem;
    @media (max-width: 1200) {
      width: 100%;
      }
}
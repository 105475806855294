@import "../../assets/css_general/InvisionExportStyles.scss";

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  #mainContainer {
    min-height: none !important;
  }

  #mainContainer .mainVideoContainerInstructions {
    min-height: none !important;
  }
}

.caracteres_chat_influencer {
  position: relative;
  bottom: -1.5rem;
  // right: 0.5rem;
  font-size: 13px;
  color: grey;
}
.UsuPuntuacion2 .Div-foto {
  margin-right: 0px;
}
.UsuPuntuacion2 .Estrellas2 {
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
.request_moderator {
  width: 150%;
}
.img_choose {
  width: 46px;
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0.5rem;
}
.image_instructions {
  width: 46px;
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0.5rem;
}

.special_border_radius {
  border-radius: 15px !important;
}

.initializeVideoDescriptionBackground {
  background: white;
  animation: initializeBackground 1s 1;
  background: $color-black-primary !important;
}

@keyframes initializeBackground {
  from {
    background-color: white;
  }
  to {
    background-color: $color-black-primary;
  }
}

.startingStreamingVideoDescriptionBackground {
  background: $color-black-primary;
  animation: startingStreamingBackground 3s 1;
  background: white;
}

@keyframes startingStreamingBackground {
  from {
    background-color: $color-black-primary;
  }
  to {
    background-color: white;
  }
}

.forceBold {
  font-weight: bold !important;
}

.enable_use {
  color: white;
  margin-bottom: -2rem;
}

.request_img {
  width: 50px;
}
.rojo {
  color: $color-primary-color;
}
.start_streaming {
  padding: 0px;
  text-align: center;
  margin-right: 15rem;
}
#Estrella2 {
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
#svg_streaming_influencer {
  width: 36%;
  position: relative;
}
.font-size {
  font-size: 1.2em;
}
.choose_options {
  position: absolute;
  right: 0;
  margin-top: -2rem;
}
.white {
  color: #fff;
}
.configure_before {
  background: $color-black-primary;
  // width: 111%;
  height: 35rem;
  margin-right: -4rem;
}
.waiting {
  background: $color-black-primary;
  // width: 111%;
  height: 35rem;
  margin-right: -4rem;
}
.configure_beforeViewer {
  // width: 111%;
  margin-right: -4rem;
  align-items: center;
  resize: both;
  object-fit: contain;
}
.videoStreaming {
  // width: 111%;
  margin-right: -4rem;
  margin-left: 5rem;
  align-items: center;
  max-width: 33rem;
  resize: vertical;
  object-fit: cover;
}
.videoStreamingDesktop {
  // width: 111%;
  margin-right: -4rem;
  margin-left: 0rem;
  align-items: center;
  max-width: 50rem;
  resize: vertical;
  object-fit: cover;
}
.UsuPuntuacion2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Estrellas2 {
  display: flex;
  justify-content: center;
}
.card-streaming {
  position: relative;
  left: 3rem;
}
.day-streaming_pre {
  right: 5rem;
  top: 5rem;
}
.chat {
  position: relative;
  left: 3rem;
}
.streaming2 {
  position: relative;
  top: 1rem;
}
.link_preview {
  width: 100%;
}
#comment_influencer {
  width: 115% !important;
}
.send_influencer {
  border-radius: 50px;
  padding: 0px 34px;
  font-size: 16px;
  height: 31px;
  border: 0;
  background-color: $color-primary-color;
  color: #fff;
  position: relative;
  // left: 12%;
  float: right;
}
.mic-off,
.vid-off,
.logo-off {
  cursor: pointer;
  background: #666666;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  border-radius: 27px;
  margin-left: 1rem;
}
.logo-off {
  padding: 0.7rem 0;
}

.normalConfirmationButton {
  background-color: $color-primary-color;
  background-image: $color-primary-color;
  color: white;
  border-width: 0;
}

.normalConfirmationButton:focus {
  background-color: $color-primary-color;
  color: white;
  border-width: 0;
  box-shadow: 0 0 0 0.2rem rgba($color-secondary-color, 0.5) !important;
}

.normalConfirmationButton:hover {
  transform: scale(1.02, 1.02);
  background-color: $color-primary-color !important;
  background-image: $color-primary-color !important;
  color: white;
}

.specialConfirmationButton {
  background-color: white !important;
  color: $color-secondary-color;
  border-color: $color-secondary-color;
  border-width: 15;
}

.specialConfirmationButton:focus {
  background-color: white;
  color: $color-primary-color;
  border-width: 15;
  box-shadow: 0 0 0 0.2rem rgba($color-secondary-color, 0.5) !important;
}

.specialConfirmationButton:hover {
  background-color: white;
  color: $color-secondary-color;
  border-color: $color-secondary-color;
  border-width: 15;
  transform: scale(1.02, 1.02);
}

.logo-stopCall {
  cursor: pointer;
  background: #666666;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  border-radius: 27px;
  margin-left: 1rem;
  align-content: center;
  align-items: center;
}
.viewers {
  width: 100%;
  display: flex;
  align-items: self-start;
  position: relative;
  bottom: 0;
  left: 0.8rem;
  top: -3rem;
}
.svg-streaming {
  display: flex;
  width: 29%;
  padding: 0.6rem;
}
.start_button_width {
  max-width: 20rem !important;
  margin: auto;
}

.streamingContainer {
  width: 100%;
  /* min-width: 80%; */
  /* height: 100%; */
  /* min-height: 75vw; */
  min-height: 55vh;
  /* min-width: 65vh; */
  max-height: 75vh;
}

#video-container {
  //width: 100%;
  //height: auto !important;
  object-fit: contain !important;
  height: 100%;
  /* min-width: 80%; */
  /* height: 100%; */
  /* min-height: 75vw; */
  min-height: 55vh;
  /* min-width: 65vh; */
  max-height: 75vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  //background: $color-black-primary;
  //border-radius: 20px;
}

#videoMediaControlOverlay {
  width: 100%;
  height: auto !important;
  z-index: 101;
  object-fit: contain !important;
  border-radius: 20px;
  /* min-width: 80%; */
  /* height: 100%; */
  /* min-height: 75vw; */
  min-height: 55vh;
  /* min-width: 65vh; */
  max-height: 75vh;
}

/*
#videoMediaControlOverlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  z-index: 1000000;
  opacity: 0;
  
}*/

.fullscreen-enabled {
  object-fit: scale-down;
}

.fullscreen {
  height: 100%;
  width: 100%;
}

#retryConnectionModal {
  display: flex;
  justify-content: center;
}

.timer-reconnecting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#retryConnectionModal .modal-content {
  width: auto !important;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

#videoMediaControlOverlay:hover {
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

.Toastify__toast--default {
  background: #fff; //#252333;
  color: #fff;
}

.Toastify__progress-bar--default {
  background: $color-primary-color;
}

.Toastify__toast-body {
  color: #252333 !important; //#fff!important;
}

.newSubscriberConnection {
  color: $color-primary-color;
  font-weight: bold !important;
}

.videoMediaControlOverlayHover {
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

.viewerModal {
  z-index: 120;
  opacity: 1;
  background-color: $color-black-primary;
  margin-top: 5%;
  margin-left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 20px;
}

.ReactModal__Overlay {
  z-index: 20;
}

.absoluteBottomModal {
}

#messageContainerViewer {
  height: 50%;
}

#messageContainer {
  height: 51%;
}

.raiseHandQueueContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 60vh;
}

@media (min-width: 1800px) {
  #messageContainer {
    height: 55% !important;
  }

  #messageContainerViewer {
    height: 65%;
  }
}

@media (min-width: 1220px) {
  #messageContainer {
    height: 51%;
  }

  #messageContainerViewer {
    height: 64%;
  }
}

@media (max-width: 1600px) and (min-width: 992px) {
  .raiseHandQueueContainer {
    max-height: 56vh;
  }

  // #messageContainer{
  //   height: 300px;
  // }

  #messageContainerViewer {
    height: 64%;
  }
}
@media (max-width: 768px) {
  .raiseHandQueueContainer {
    max-height: 58vh;
  }

  .chatContainer {
    min-height: 65vh !important;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .raiseHandQueueContainer {
    max-height: 56vh;
  }
  #messageContainer {
    height: 45%;
  }

  #messageContainerViewer {
    height: 55%;
  }
}
.chatMain {
  @media (min-width: 1025px) {
    width: 340px;
    max-width: 340px;
  }
  @media (max-width: 1024px) {
    width: auto !important;
    max-width: auto !important;
  }
}

.chatContainer {
  height: 70vh !important;
}

.chatInput {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  // margin: 0;
  margin: 12px 0;
  // margin-top: 5%;
  // margin-bottom: 5%;
}

.viewerList {
  min-height: 70.5%;
  max-height: 70.5%;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 2%;
  margin-bottom: 0%;
  overflow-y: auto;
}

@media (max-width: 522px) {
  .viewerList {
    min-height: 61%;
  }
}

.centerContent {
  display: flex;
  justify-items: center;
  align-items: center;
  padding-top: 1%;
  text-align: center;
}

.centerContentViewerItem {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
  margin-left: 2%;
  margin-right: 2%;
  word-break: break-all;
  width: 10%;
}

.viewerItemAvatar {
  width: 15vw;
  height: 15vw;
  max-height: 85px;
  max-width: 85px;
}

.viewerPanelGiveTurnButton {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.MobileViewersWaitingWarning {
  color: $color-primary-color;
  position: fixed;
  top: 2.75%;
  left: 40%;
  width: 20%;
  font-weight: bold;
  z-index: 5;
  text-align: center;
}

.mobileCenterContentViewerItem {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
  margin-left: 2%;
  margin-right: 2%;
  word-break: break-all;
  width: 100%;
}

.mobileViewerItemAvatar {
  width: 15vw;
  height: 15vw;
  max-height: 85px;
  max-width: 85px;
}

.viewerModalButton {
  cursor: pointer;
}

.Choose_raise {
  width: 80%;
  margin-bottom: 0 !important;
}

.streamingFullContainer {
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (min-width: 1025px) {
    flex-direction: row;
  }
  display: flex;
  justify-content: center;
}

.openModeIndicator {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  background: #666666;
  border-radius: 27px;
  margin-left: 1rem;
  width: 3rem;
  height: 3rem;
}

.riseHandButton {
  cursor: pointer;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  background: #666666;
  border-radius: 27px;
  margin-left: 1rem;
  width: 3rem;
  height: 3rem;
}

@media (max-width: 992px) and (min-height: 600px) {
  .mainVideoContainerVideo {
    min-height: 30vh !important;
    margin-bottom: 2.5%;
  }
}

@media (max-width: 992px) and (max-height: 600px) {
  .mainVideoContainerVideo {
    min-height: 75vh !important;
    margin-bottom: 2.5%;
  }
}

@media (min-width: 768px) {
  .messageUserAvatar {
    width: 40px;
    height: 40px;
  }

  .chatSize {
    max-height: 52.5vh;
  }

  .vertical-center {
    width: 95%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .messageUserAvatar {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 992px) {
  #video-container {
    min-height: 0px !important;
    margin: 0 !important;
  }

  .streamingContainer {
  }
}

.Logo3 {
  width: 8%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .mainVideoContainer {
    margin-top: 2vh;
    //min-height: 35vh;
    margin-bottom: 2vh;
  }

  .mainVideoContainerVideo {
    //min-height: 25vh;
  }

  .messageUserAvatar {
    width: 8.5vw;
    height: 8.5vw;
  }

  .mainVideoContainerInstructions {
    //min-height: 90vh;
  }

  .chatSize {
    max-height: 14vh;
  }

  .vertical-center {
    width: 95%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}


@media (max-width: 1600px) {
  .mainVideoContainerInstructions {
    min-height: 850px;
  }
}

.mainVideoContainerInstructions {
  min-height: 650px;
}

@media screen and (min-width: 1600px) {
  .svg-streaming {
    margin-right: -8rem;
  }

  .configure_before {
    // width: 104%;
  }
  .choose_options {
    position: absolute;
    right: 10rem;
    margin-top: -2rem;
  }

  .mainVideoContainerInstructions {
    min-height: 650px;
  }

  .start_streaming {
    padding: 0px;
    text-align: center;
    margin-right: 15rem;
  }
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: $color-primary-color;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 96, 0.25);
}

.MobileStreamContainer {
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.MobileStreamContainer div {
  overflow-y: hidden;
  overflow-x: hidden;
}

.ViewersPanel {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ViewersPanel .viewersCounter {
  position: absolute;
  right: 5%;
  top: 2.5%;
  z-index: 5;
}

.ViewersPanelHeader {
  padding-left: 7.5%;
  padding-right: 7.5%;
}

.mobileInstructions {
  z-index: 5;
}

.mobileInstructionsList {
  max-height: 60vh;
  overflow-y: scroll;
}

.mobileInstructions .instructionRow {
  margin-bottom: 0.25% !important;
}

.mobileInstructions .enable_use {
  margin-bottom: 0.25% !important;
}

.leaveEventButtonManager {
  position: fixed;
  z-index: 5;
  left: 5%;
  top: 2.5%;
  opacity: 0.25;
}
.leaveEventButtonManager img {
  width: 5vw;
  height: 5vw;
  max-width: 20px;
  max-height: 20px;
}

.leaveEventButtonManager:hover {
  opacity: 1;
  transform: scale(1.075, 1.075);
  -webkit-transform: scale(1.075, 1.075);
  -moz-transform: scale(1.075, 1.075);
}

.leaveEventButtonManager button {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 50px;
  border-color: white !important;
  padding-bottom: 15%;
  padding-top: 15%;
  padding-right: 70%;
  padding-left: 70%;
}

.mobileChatInput {
  position: fixed;
  bottom: 2.5%;
  left: 10%;
  width: 80%;
  z-index: 15;
  color: white;
  background-color: #00000057;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 2.5%;
  padding-right: 2.5%;
  border-radius: 50px;
  border-color: #ffffff3d;
  border-width: 1.5px;
}

.mobileChatInput:focus {
  border-color: $color-primary-color;
  border-width: 1.5px;
  outline: none !important;
  box-shadow: 0 0 10px $color-secondary-color;
}

.MobileChatContainer {
  position: absolute;
  top: 40%;
  left: 5%;
  max-height: 30vh !important;
  width: 90%;
  z-index: 50;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.MobileChatContainerLandscape {
  position: absolute;
  top: 25%;
  left: 5%;
  max-height: 45vh !important;
  width: 90%;
  z-index: 50;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mobileDisabledChat {
  position: absolute;
  bottom: 2.5%;
  left: 0%;
  width: 100%;
  z-index: 15;
  color: white;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}

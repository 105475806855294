.activity{
  margin-right: -1rem;
}
.p_activity{
  color: black!important;
}
.ago{
  position: absolute;
    margin-top: 1.5rem!important;
    margin-left: 3.5rem;
    color: grey!important;
}

#ago {
  position: absolute;
  margin-top: 2.3rem !important;
  margin-left: 4rem;
  color: grey !important;
  left: 3.5rem;
}
#ago2 {
  position: absolute;
    margin-top: -1.7rem !important;
    margin-left: 0rem;
    color: grey !important;
    left: 3.5rem;
}

.image_button{
  margin-top: -0.3rem;
}
#ago3 {
  position: absolute;
  margin-top: -1.7rem !important;
  margin-left: 0rem;
  color: grey !important;
  left: 3.5rem;
  bottom: 9px;
}
.user_emergency{
  top: 1rem;
}
.follow{
  border-radius: 50px;
    padding: 0px 14px;
    font-size: 15px;
    height: 30px;
    border: 0;
    background-color: #ff5a60;
    color: #fff;
    margin-left: 2rem;
    margin-top: 1rem;
}
.p_emergency{
  position: relative;
    bottom: 1rem;
    right: -3.5rem;
}
.img_activity{
  width: 6rem;
  position: absolute!important;
    top: 0;
    right: 0!important;
}
.Nav-menu {
  .profile::before {
    left: 23%;
  }
  .dropdown-item {
    font-weight: 600 !important;

    padding: 0;
  }
  .dropdown-menu.show {
  
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 33rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-top: 1rem;
}
  li {
    text-align: center;

    span {
      color: #7a7883;
      word-break: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 5px;

    }
  }

  i {
    font-size: 25px;
    color: #b3b8c1;
  }

  li {
    &:hover {


    i {
      color: var(--color-primary-color);

    }

    // span {
    //   color: #fff !important;

    // }
    .ago{
      color: grey!important;
    }
    .p_activity{
      color: black!important;
    }
  }
}

.activo {
  i {
    color: var(--color-primary-color);

  }

  // span {
  //   color: #fff !important;

  // }
}

}
@import "../../../assets/css_general/InvisionExportStyles";


.Tag{
  color: black;

  cursor:pointer;
  border-radius: 20px;
  border: 1px solid black;
  padding: 6px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 16px;
  margin-right: 16px;
  
}

@import "../../../assets/css_general/InvisionExportStyles.scss";

.ReactCrop__image {
  display: block;
  max-width: 100%;
  // max-height: fill-available;
  max-height: 400px;
}

.css-1jq9zvi-Switch {
  left: 10px !important;
}

.alignMobile {
  align-self: center;
  text-align: center;
  justify-content: center;
}

.titleDescriptionEventPreview {
  h1,
  h5 {
    padding: 0px 10px;
  }
}

.example-custom-input {
  width: 100%;
  img {
    width: 20px;
    height: 20px;
  }
}

.TimePickerContainer {
  cursor: pointer;
  .react-datepicker-wrapper {
    cursor: pointer;
    border: 1px solid #d8dce5;
    border-radius: 5rem;
    height: 3rem;
    padding: 10px 16px;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    cursor: pointer;
    width: 100%;
  }

  .iconContainer {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    height: 100%;
    align-content: center;
    justify-content: center;
  }
}

.TimePickerContainerWrapper {
  width: 100%;
}
.react-date-picker__inputGroup__input {
  height: auto !important;
}

.PublicEventOptionsContainer {
  span {
    font-size: 0.75rem;
  }

  .SwitchContainer {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 3rem;
    padding: 10px 16px;

    label {
      margin-bottom: 0 !important;
    }
  }
}

@media (max-width: 992px) {
  .PublicEventOptionsContainer {
    margin-top: 15px;
  }
}

.DefaultImageContainer {
  display: flex;
  flex-direction: column;

  h2 {
    display: inline-block;
    font-size: 1.5rem;
    color: black;
    margin-top: 1rem;
  }

  .ImageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;

    img {
      width: 200px;
      height: auto;
      cursor: pointer;
    }
  }

  
}

.ImageContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;

  width: 75%;
}

.ImageContainerModalBody {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.DefaultImageContainer .gotmyButton, .ImageContainerModalBody .gotmyButton {
    padding: 4px 16px;
    background-color: $color-primary-color;
    background-image: $color-primary-color;
    color: white;
    border-radius: 25px;
    border-width: 0px;
    cursor: pointer;
    max-width: 225px;
    align-self: center;
  }

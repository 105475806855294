.container_my_balance{
    padding-top: 9rem;
}

#mybalance{
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    @media(max-width: 768px){
        padding-top:3rem;
        padding-left:1.5rem;
        padding-right:1.5rem;
    }
}

.grey-description {
    color: grey
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

.text-center{
    text-align: center;
}

.float-right {
    float: right;
}

.border-right-gray {
    border-right: solid 2px gray;
}

.card {
    border-radius: 7px;
    padding: 25px;
}

.font-x-large {
    font-size: x-large;
}

.color-green {
    color: rgb(6, 192, 6);
}

.font-weight-700 {
    font-weight: 700 !important;
}

.inline-grid {
    display: inline-grid;
}

.padding-right-10 {
    padding-right: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.Logo {
    width: 250px;
    @media (max-width:768px){
        width: 240px;
    }
}

.modal_payment_methods{
    border-radius: 3rem;
    height: 2.7rem;
}

.brand-image {
    float: left;
    width: 50px;
    margin-right: 15px;
}

.float-right {
    float: right;
}
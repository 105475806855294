.Live-Events-Page{

  .Search-div-peq i{
    top: -8px!important;
    font-size: 25px;
  }
  .Upcoming-live-events, .Past-live-events{
    @media(max-width: 768px){
      margin-top:0rem !important;
    }
    margin-top: 2rem;
  }


  .Search-div-peq{
    width: 100%;

    input{
      padding-left: 60px;
      padding-top: 23px;
      padding-bottom: 23px;
      font-size: 18px;
      border-radius: 50px;
      margin-top: -20px;
      width: 100%;

    }
 img {
      position: absolute;
      left: 20px;
      top: -8px;
    }
  }

  .Past-live-events{
    .Search-div-peq{

      position: absolute;
      width: 300px;
      right: 6%;

      input{
        padding-left: 60px;
        padding-top: 23px;
        padding-bottom: 23px;
        font-size: 18px;
        border-radius: 50px;
        margin-top: -20px;
        width: 100%;

      }
      img {
        position: absolute;
        left: 20px;
        top: -8px;
      }
    }

  }
  .Fila-live-events{
    flex-wrap: wrap;
    // .LiveEventsCard:nth-child(even){
    //     padding-left: 0;
    // }
    // .LiveEventsCard:nth-child(odd){
    //   padding-right: 0;
    // }
  }
}
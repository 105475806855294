@import "../../../assets/css_general/InvisionExportStyles";


.Home-pasos{
  @media(max-width:768px){
    .sec{
      padding:16px 0;
      display:flex;
      flex-direction: column;
      align-items:center;
      justify-content: center;
    }
    margin: 24px 16px;
    h1{ 
      padding-top:12px;
      font-size:36px;
    }
    h2{
      font-size: 28px;
      margin-bottom: 0px;
    }
    h4 {
      font-size:20px;
      align-self: flex-start;
    }
    p{ width:100%;}
  }


  
    h1,h2{
      text-align: center;
    }
  h1{
    color: black;
  }
  h2{
    color: $color-grey-primary;
    line-height:46px;
    font-size: 30px;
    margin-top: 15px;
   @media(max-width: 768px){
      font-size: 22px;
    }
  }
  h4{
    padding-left: 35px;
    font-size:24px;
    font-weight: 600;
  }
  p{
    @media (max-width:768px){
      width:100% !important;
    }
    width: 80%;
    font-size:16px
  }
  .caja{
    position: relative;
  padding-top: 20px;
    .numero{
      @media(max-width: 768px){
        font-size:40px !important;
        top:-40px
      }
    position: absolute;
top: -50px;
      left: 0;
      color: $color-grey-primary;
      font-weight: 500;
      font-size: 60px;
      z-index: 1;
    }
  }
hr{
  background:$color-primary-color;
height: 4px;
  width: 30px;
  float: left;
}
}
.input_label {
  width: 100%;
  height: 100%;
  background: #ff5a60;
  padding: 0.5rem;
  border-radius: 1.3rem;
  cursor: pointer;
}
.image-upload>input {
    display: none;
  }
  .image-upload img
{
    cursor: pointer;
}
// .photo_input{
//     width: 45px;
//     border-radius: 40px;
// }
.input_label{
    margin-left: 0.77rem;
    margin-top: 0.7rem;
    width: 22px;
}
.publish_label{
    background: #ff5a60;
    width: auto;
    padding: 0.8rem;
    margin: auto;
    border-radius: 50%;
}
.input_publish{
    justify-content: center;
    width: 29rem;
    border: 1px solid grey;
    height: 17rem;
    align-items: center;
}
.file_input{
    width: 45px;
    height: 45px;
    // background: #F7F8FA;
    border-radius: 30px;
}
.input_group_name{
    width: 14rem;
    height: 3rem;
    border-radius: 20px;
    border: 1px solid #E2E6EF;
    margin-left: 1rem;
}
.save{
    padding: 0px 17px;
    width: 10rem;
    height: 2rem;
    font-size: 16px;
}
.cancel {
    border-radius: 50px;
    padding: 0px 30px;
    font-size: 16px;
    height: 51px;
    border: 0;
    background-color: #B4B8C1;
    color: #fff;
    height: 2rem;
    margin-left: 1rem;
}
.div_save::after{
    content: "";
    display: block;
    width: 19rem;
    height: 1px;
    background: #E2E6EF;
    margin-top: 1rem;
    position: relative;
    right: 1rem;
}
.file_label_publish{
    width: 57px;
}
.photo_input_publish:nth-child(2){
    width:300px;
}
h3 {
    margin-left: 15px;
  }
  .fileInput {
    border-bottom: 4px solid #d3d3d3;
    border-right: 4px solid #d3d3d3;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    padding: 10px;
    margin: 15px;
    cursor: pointer;
    display: none;
  }
  .imgPreview {
    border-radius:8px;
    text-align: center;
    // margin: 5px 15px;
    height: 21rem;
    width: 100%;
    border: 1px solid #D8DCE5;
    position: relative;
    // right: 2rem;
  }
  .imgPreview img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .previewText {
    width: 100%;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17rem;
}
  .submitButton {
    padding: 12px;
    margin-left: 10px;
    background: #fff;
    border: 4px solid #d3d3d3;
    border-radius: 15px;
    font-weight: 700;
    font-size: 10pt;
    cursor: pointer;
  }
  .submitButton:hover {
    background: #efefef;
  }
  .centerText {
    text-align: center;
    width: 500px;
  }
  #file-input-change{
    float: right;
    position: relative;
    bottom: 5rem;
    right: 5.5rem;
    width: 0;
  }
  #input_label_change{
    width: 2.7rem;
  }
@import "../../../assets/css_general/InvisionExportStyles";


.nombre_moderator{
  font-size: 18px!important;
  position: relative;
  right: 0rem;
  top:0rem;
}
.Div-foto_moderator{
 display: flex; 
}
.UsuPuntuacion3{
  width: 111%;
  position: unset;
  top: 3rem;
  right: 0rem;
  flex-direction: column;
    align-items: center;
    height: 5rem;
}
.parrafo_moderator{
  color : #ff5a60;
  position: relative;
  bottom: 2rem;
  left: 5rem;
}
.UsuPuntuacion3 .Estrellas2 {
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
.UsuPuntuacion3{
  display: flex;
  align-items: flex-start;
  .Div-foto{
    margin-right: 25px;
  }
  .Foto {
    border-radius: 50%;
    border: 1px solid $color-primary-color;
    margin-right: 20px;
    align-self: center;
    width: 60px;
  }

  .Div-foto {
    position: relative;
    width: 60px;
  }

  .Nombre {
    font-size: 22px;
    font-weight: 600;
    display: block;
  }
  .Icono {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 20px;
  }
  .Estrellas{
    margin-left: auto;
    margin-right: 15px;
    img{
      width: 95px;
    }
  }
}
@import "../../assets/css_general/InvisionExportStyles.scss";

.nav {
	background-color: $color-black-primary !important;
	width: 100%;
	height: 100%;
	border: none;
	font-weight: bold;
	font-size: 20px;
	justify-content: center;
	a {
		&:hover {
			border: none;
		}
	}
}
.profileLayout{
	display:flex;
	flex-direction: row;
	@media(max-width: 768px){
		flex-direction: column;
	}
}
.publicProfileNav {
	width: 100%;
}
.nav-tabs {
	.nav-link {
		color: white;
		margin-right: 45px;
		border: none;
	}
	.nav-link.active {
		background-color: $color-black-primary !important;
		color: white;
		border-bottom: 4px solid var(--color-primary-color);
		border-color: $color-black-primary;
	}
	.nav-item.show {
		.nav-link {
			background-color: $color-black-primary !important;
			color: white;
			border-bottom: 4px solid var(--color-primary-color);
			border-color: $color-black-primary;
		}
	}
}
.public_profile {
	padding-top: 0px !important;
}
.tab-content {
	background-color: white;
	padding-top: 30px;
}
.t {
	width: 100%;
	background-color: $color-black-primary;
}
.close.rbt-close {
	display: none !important;
}
.date-picker {
	:hover{
		cursor: pointer;
	}
	width: 100%;
	.react-date-picker__wrapper {
		border: 1px solid #D8DCE5;
		border-radius: 5rem;
		height:3rem;
		padding: 10px 16px;
		.react-date-picker__inputGroup__input {
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
		}
		.react-date-picker__calendar-button.react-date-picker__button {
			margin-top: -0.4rem;
		}
	}
}
.react-date-picker.react-date-picker--closed.react-date-picker--disabled.date-picker {
	border-radius: 50px;
}
.fullname-container {
	width: 29rem;
	.names-container {
    margin-top: 1rem;
  }
	input {
		width: 100% !important;
	}
}
label{
	font-size: 14px;
}

.location-field{
	// @media screen and (max-width: 1150px) {
	// 	width: 100%;
	// }
	width: 60%;
}
.birthday-field{

	width: 40%;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
}
.required-field{ 
	display: flex;
}
.required{ 
	font-size: 16px;
	color:#ff5a60;
	margin-left: 4px;
}
.field{
	margin-top:12px;
}
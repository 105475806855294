@import "../../../../assets/css_general/InvisionExportStyles";

.Past_influencer{
  .Header {
    // background: #D8DCE5;
    background-size: cover!important;
    background-repeat: no-repeat;
  }
  .Start{
    position: absolute;
    left: 20px;
    top: 20px;
    color: white;
    font-size: 16px;
    border-radius: 15px;
    padding: 3px 13px;
    background-color: $color-primary-color;
}
}
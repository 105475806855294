.cancelEvent {
  padding: 10px 20px !important;
}
.Live-Event-Detail {
  margin-top: 97px;
  position: relative;
  padding-top: 25px;
  padding-bottom: 0;
  i {
    font-size: 30px;
    :hover {
      cursor: pointer;
    }
  }
  .Mb {
    margin-bottom: 2rem;
  }

  .D-title {
    font-size: 22px;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .Desc {
    font-size: 16px;
    margin-top: 5px;
    font-weight: 600;
    padding: 5px;
  }
  .Btn-rojo-peq:hover {
    color: #fff;
  }
}

.LinkInput {
  display: inline-block;
  word-wrap: break-word;
  overflow-wrap: break-word!important;
  font-style: normal;
  max-width: 100%;
  background: #f3f3f3;
  cursor: text;
  border-radius: 15px;
  padding: 10px 15px;
}

@media (max-width: 768px) {
  .privateInfoContainer,
  .privateInfoContainer p {
    margin: 0px 15px;
  }
  .privateInfoContainer p {
    width: 80%;
    padding-bottom: 10px;
  }
  .privateInfoContainer h4 {
    width: 70%;
  }
  .privateInfoContainer span {
    margin-left: 25px;
  }
  .LinkInput {
    margin: 0 10px;
    margin-right: 25px;
  }
  .ShareBuyContainer {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@import "../../../../assets/css_general/InvisionExportStyles.scss";

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.caracteres_chat_influencer{
  position: relative;
    bottom: -0.5rem;
    // right: 0.5rem;
    font-size: 13px;
    color: grey;
}
.placeholder-text{
  ::placeholder{
    color:#D3D3D3;
  }
}
.UsuPuntuacion2 .Div-foto {
  margin-right: 0px;
}
.UsuPuntuacion2 .Estrellas2 {
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
.request_moderator{
  width: 150%;
}
.img_choose{
  width: 46px;
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0.5rem;
  
}
.image_instructions{
  width: 46px;
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0.5rem;
}

.instructionRow{
  place-content: center;
  place-items: center;
  place-self: center;
  padding-bottom: 3.5%;
  padding-top: 3.5%;
}


@media (max-width: 768px){

  .instructionRow{
    padding-bottom: 3%;
    padding-top: 3%;
  }
}


@media (max-width: 992px) and (min-width: 768px){
  .instructionRow{
    padding-bottom: 1.25%;
    padding-top: 1.25%;
  }
}


@media (max-width: 1200px) and (min-width: 992px){
  .instructionRow{
    padding-bottom: 10%;
    padding-top: 10%;
  }
}

.enable_use{
  color: white;
  margin-bottom: -2rem;
}

.request_img{
  width: 50px;
}
.rojo{
  color: $color-secondary-color;
  color: $color-primary-color;
}
.start_streaming{
  padding: 0px;
  text-align: center;
  margin-right: 15rem;
}
#Estrella2{
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
#svg_streaming_influencer{
  width: 36%;
  position: relative;
}
.font-size{
  font-size: 1.2em;
}
.choose_options{
  position: absolute;
  right: 0;
  margin-top: -2rem;
}
.white{
  color: #fff;
}
.configure_before{
  background: #252233;
  // width: 111%;
  height: 35rem;
  margin-right: -4rem;
}
.waiting{
  background: #252233;
  // width: 111%;
  height: 35rem;
  margin-right: -4rem;
}
.configure_beforeViewer{
  
  // width: 111%;
  margin-right: -4rem;
  align-items: center;
  resize: both;
  object-fit: contain;
}
.videoStreaming{

  // width: 111%;
  margin-right: -4rem;
  margin-left: 5rem;
  align-items: center;
  max-width: 33rem;
  resize: vertical;
  object-fit: cover;
}
.videoStreamingDesktop{

    // width: 111%;
    margin-right: -4rem;
    margin-left: 0rem;
    align-items: center;
    max-width: 50rem;
    resize: vertical;
    object-fit: cover;
}
.UsuPuntuacion2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Estrellas2{
  display: flex;
  justify-content: center;
}
.card-streaming{
  position: relative;
  left: 3rem;
}
.day-streaming_pre{
  right: 5rem;
  top: 5rem;
}
.chat{
  position: relative;
  left:3rem;
}
.streaming2{
  position: relative;
  top: 1rem;
}
.link_preview{
  width: 100%;

}
#comment_influencer{
  width: 115%!important;
}
.send_influencer{
  border-radius: 50px;
    padding: 0px 34px;
    font-size: 16px;
    height: 31px;
    border: 0;
    background-color: $color-primary-color;
    color: #fff;
    position: relative;
    // left: 12%;
    float: right;
}
.mic-off, .vid-off, .logo-off{
  background: #666666;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  border-radius: 27px;
  margin-left: 1rem;
}
.logo-off{
  padding: 0.7rem 0;
}

.viewers{
  width: 100%;
  display: flex;
  align-items: self-start;
  position: relative;
  bottom: 0;
  left: 0.8rem;
  top: -3rem;
}
.svg-streaming{
  display: flex;
  width: 29%;
  padding: 0.6rem;
}
.start_button_width{
  max-width: 20rem!important;
  margin: auto;
}
@media screen and (min-width:1600px){
  .svg-streaming{
    margin-right: -8rem;
    
  }
  .configure_before {
    // width: 104%;
}
  .choose_options {
    position: absolute;
    right: 10rem;
    margin-top: -2rem;
}
.start_streaming {
  padding: 0px;
  text-align: center;
  margin-right: 15rem;
}
}
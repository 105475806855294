.list_chat {
  position: relative;
}
#title_group {
  font-size: 18px !important;
}
.last_message {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: grey;
  font-size: 16px;
}
#title_name {
  font-size: 17px;
  margin-left: 42px !important;
}
.img_user_chat {
  object-fit: cover;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.chat_time {
  color: grey;
  font-size: 17px;
}
// .list_chat_details{
//     height: 6rem;
// }
.box-message {
  position: relative;
  bottom: 2rem;
}

#chatDivContainer {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d8d8d8;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

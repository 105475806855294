.card{
    height: 50%;
    border-radius: 1rem;
}
.card-img-top {
    width: 11%;
    position: absolute;
    right: 1rem;
    top: 2rem;
}
#divider::after{
    content: "";
    width: 105%;
    background: red;
    color: red;
    height: 2px;
    display: block;
    margin-left: -0.3rem;
    margin-top: 0.5rem;
}
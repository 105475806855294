@import "../../../assets/css_general/InvisionExportStyles";

.button_moderator{
  border-radius: 50px;
  padding: 0px 15%;
  font-size: 16px;
  height: 31px;
  border: 0;
  background-color: #ff5a60;
  color: #fff;
  margin-bottom: 5%;
}




@media (min-width: 1200px){
  .AttendeesInfluencerChoose{
    padding-left: 5%;
    padding-right: 15%;
  }

}

@media (max-width: 1200px){
  .Foto{
    width: 7vw !important;
    height: 7vw !important;
    border-radius: 50% !important;
  }
}

@media (max-width: 992px){
  .Foto{
    width: 125px !important;
    height: 125px !important;
    border-radius: 50% !important;
  }
}

.AttendeesInfluencerChoose{
margin-top: 25px;
.Foto{
  //margin-right: 28px;
  width: 2.5vw;
  border-radius: 2.5vw;

}
  .Nombre{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    //margin-left: -1rem;
  }
  .Hor {
    color: #7a7883;
    display: flex;
    font-weight: 500;
    margin-top: 4px;
    font-size: 0.9rem;

    img {
      width: 20px;
      margin-right: 5px;
      //margin-left: -18px;
    }
  }
}
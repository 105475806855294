@import "../../../assets/css_general/InvisionExportStyles";


.PublishNav{
  padding-left: 0!important;
  padding-right: 0!important;
}
.cancel {
  border-radius: 50px;
  padding: 0px 18px!important;
  font-size: 15px!important;
  height: 51px;
  border: 0;
  background-color: #B4B8C1;
  color: #fff;
  height: 2rem;
  margin-left: 1rem;
}
.FechaCat13{
  background: $color-black-primary;
  display: flex;
  color: #fff;
  padding: 1.2rem 1rem 1rem 1rem;
}
.Logo5{
  width: 3.5%;
    height: 5%;
}
.title_request{
  @media(max-width: 768px){
    font-size: 18px !important;
    margin-left: 1rem !important;
  }
  font-size: 22px;
  margin-left: 1.5rem;
}
.request-streaming.send_request{
  background: #ff5a60;
  margin-left:1rem;
  padding: 0px 25px;
}
.request-streaming{
  float: right;
  position: relative;
  bottom: 3.5rem;
  border-radius: 50px;
  padding: 0px 19px;
  font-size: 15px;
  height: 34px;
  border: 0;
  background-color: #B4B8C1;
  color: #fff;
  right:2rem;
}
.NombreCat{

  h1{
    font-size: 2.5rem;
    font-weight: 600;
  }

  h2{
    color: #ff5a60;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .Hor{
    color: #7a7883;
    display: flex;
    font-weight: 500;
    margin-top: 4px;
    font-size: 1rem;
    img{
      width: 15px;
      margin-right: 5px ;
    }
  }


}
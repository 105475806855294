
.rbt-input-wrapper{
  display: flex;
}

.rbt-token-remove-button{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
 
}
.disable{
  font-size: 18px!important;
  color: #7a7883!important;
  padding-bottom: 10px!important;
  text-align: center!important;
  border: unset!important;
  cursor: pointer!important;
}
.active{
  // color: white!important;
  // border-bottom: 4px solid #ff5a60!important;
  // cursor: unset!important;
}
// #fix_category{
//   display:none;
// }
.close.rbt-close{
  color: white;
  background: black!important;
  border-radius: 3rem;
  width: 1.1rem;
  font-size: 1.2em;
  margin-top: 0.1rem;
}
.rbt-token.rbt-token-removeable{
  border: 1px solid #D8DCE5;
  border-radius: 3rem;
  padding: 0.5rem 0.5rem 0rem 1rem;
  margin-right:0.5rem;
}
.img_language{
  width: 1.5rem;
}
.country_select{
  @media(max-width: 768px){
    padding-right: 0px;
  }
padding-right: 24px;
margin-top: -0.4rem;
width: 100%;
}
.rbt-input-multi{
  height: 4rem;
  // width: 94.5%;
  margin: 0 0rem 2rem;
}
.textareas_profile{
  margin-left: -17rem;
}
.public_profile{
  .Toastify__close-button.Toastify__close-button--default{
    display: none;
  }
  padding-top: 13rem;
  
  .profile_social{
    border: 1px solid #D8DCE5;
    height: 35rem;
  }
  .information_profile{
    position: relative;
    // right: 3rem;
    margin-top: 2rem;
  }
 
  .imgPreview {
    text-align: center;
    margin: 5px 15px;
    height: 13rem;
    width: 100%;
    border: 1px solid #ff5a60;
    position: relative;
    right: 0rem;
    border-radius: 48%;
    left: 3rem;
}
.imgPreview img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.file_input {
  position: relative;
  bottom: 2.5rem;
  right: 0.5rem;
}
#file-input-change {
  float: left;
  position: relative;
  bottom: 4rem;
  right: 0rem;
  width: 0;
  left: 18%;
}
@media screen and (max-width: 1150px){
  input[type="text"] {
    // width: 15rem!important;
  }
  #hor-profile::after {
    width: 14rem!important;
  }
  .birthday_div_profile{
    width: 70%;
  }
  input[type="date"]{
    width: 9rem!important;
  }
  select{
    width: 9rem!important;
  }
  textarea{
    width:90%;
    
  }
  .rbt-input-multi {
    // width: 90%;
}
}
}
.categories-h2{
  font-size: 23px;
  padding: 0 0rem;
}
.categories-p{
  font-size: 1.1rem;
  color: grey;
  padding: 0.5rem 20rem 1rem 0rem;
}
.img_categorias{
  cursor: pointer;
  width: 90%;
    height: 9rem;
    border-radius: 1rem;
}
.form_category{
 
  @media(max-width:528px){
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 0.5rem;
  }
  @media(max-width:1200px){
    grid-template-columns: repeat(5, 1fr) !important;
    grid-gap: 0.5rem;
  }
  @media(max-width:768px){
    grid-template-columns: repeat(3, 1fr) !important;
    grid-gap: 0.5rem;
  }
  display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0.5rem;
    grid-auto-rows: minmax(100px, auto);
    // padding: 1rem;
}

 input[type="checkbox"]{
	display: none;
}
 input[type="checkbox"] + label {
  z-index: 9;
}
input[type="checkbox"]:checked + label::before{
  content: '';
  display: block;
  background-image: url('../../assets/img/icons/ic-category-fav-white.svg');
  width: 5rem;
  height: 2rem;
  position: absolute;
  background-repeat: no-repeat;
  margin-left: 11%;
  margin-top: 1rem;
  z-index: 9;
}
input[type="checkbox"]:checked + label::after{
  content: '';
    display: block;
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    background-repeat: no-repeat;
    background-color: #FF5A60;
    opacity: 0.7;
    margin-top: 0 !important;
    margin-left: 0rem !important;
    top: 0 !important;
    border-radius: 1rem;
    transition: 1s;
    cursor: pointer;
}

.InfluencerPresentationEditProfile{
  display: flex;
    align-items: center;
    justify-content: center;
}
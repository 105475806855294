@import "../../../assets/css_general/InvisionExportStyles.scss";

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.caracteres_chat_influencer {
  position: relative;
  bottom: -1.5rem;
  // right: 0.5rem;
  font-size: 13px;
  color: grey;
}
.UsuPuntuacion2 .Div-foto {
  margin-right: 0px;
}
.UsuPuntuacion2 .Estrellas2 {
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
.request_moderator {
  width: 150%;
}
.img_choose {
  width: 46px;
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0.5rem;
}
.dominantSpeakerElement {
  position: absolute;
  z-index: 10;
  bottom: 7.5%;
  right: 5%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dominantSpeakerElement img {
  width: 4vw;
  height: 4vw;
  object-fit: contain;
  border-radius: 2vw;
}

.dominantSpeakerElement p {
  padding: 0;
  margin-top: 5%;
  margin-bottom: 5%;
  color: #fff;
}

.dominantSpeakerElement h4 {
  color: #fff;
  font-size: medium;
  padding: 0;
  margin: 0;
}

.image_instructions {
  width: 46px;
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0.5rem;
}

.special_border_radius {
  border-radius: 15px !important;
}

.initializeVideoDescriptionBackground {
  background: white;
  animation: initializeBackground 1s 1;
  background:  $color-black-primary !important;
}

@keyframes initializeBackground {
  from {
    background-color: white;
  }
  to {
    background-color:  $color-black-primary;
  }
}

.startingStreamingVideoDescriptionBackground {
  background:  $color-black-primary;
  animation: startingStreamingBackground 3s 1;
  background: white;
}

@keyframes startingStreamingBackground {
  from {
    background-color:  $color-black-primary;
  }
  to {
    background-color: white;
  }
}

.forceBold {
  font-weight: bold !important;
}

.enable_use {
  color: white;
  margin-bottom: -2rem;
}

.request_img {
  width: 50px;
}
.rojo {
  color: $color-primary-color;
}
.start_streaming {
  padding: 0px;
  text-align: center;
  margin-right: 15rem;
}
#Estrella2 {
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
#svg_streaming_influencer {
  width: 36%;
  position: relative;
}
.font-size {
  font-size: 1.2em;
}
.choose_options {
  position: absolute;
  right: 0;
  margin-top: -2rem;
}
.white {
  color: #fff;
}
.configure_before {
  background:  $color-black-primary;
  // width: 111%;
  height: 35rem;
  margin-right: -4rem;
}
.waiting {
  background:  $color-black-primary;
  // width: 111%;
  height: 35rem;
  margin-right: -4rem;
}
.configure_beforeViewer {
  // width: 111%;
  margin-right: -4rem;
  align-items: center;
  resize: both;
  object-fit: contain;
}
.videoStreaming {
  // width: 111%;
  margin-right: -4rem;
  margin-left: 5rem;
  align-items: center;
  max-width: 33rem;
  resize: vertical;
  object-fit: cover;
}
.videoStreamingDesktop {
  // width: 111%;
  margin-right: -4rem;
  margin-left: 0rem;
  align-items: center;
  max-width: 50rem;
  resize: vertical;
  object-fit: cover;
}
.UsuPuntuacion2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Estrellas2 {
  display: flex;
  justify-content: center;
}
.card-streaming {
  position: relative;
  left: 3rem;
}
.day-streaming_pre {
  right: 5rem;
  top: 5rem;
}
.chat {
  position: relative;
  left: 3rem;
}
.streaming2 {
  position: relative;
  top: 1rem;
}
.link_preview {
  width: 100%;
}
#comment_influencer {
  width: 115% !important;
}
.send_influencer {
  border-radius: 50px;
  padding: 0px 34px;
  font-size: 16px;
  height: 31px;
  border: 0;
  background-color: $color-primary-color;
  color: #fff;
  position: relative;
  // left: 12%;
  float: right;
}
.mic-off,
.vid-off,
.logo-off {
  background: #666666;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  border-radius: 27px;
  margin-left: 1rem;
}
.logo-off {
  padding: 0.7rem 0;
}

.viewers {
  width: 100%;
  display: flex;
  align-items: self-start;
  position: relative;
  bottom: 0;
  left: 0.8rem;
  top: -3rem;
}
.svg-streaming {
  display: flex;
  width: 29%;
  padding: 0.6rem;
}
.start_button_width {
  max-width: 20rem !important;
  margin: auto;
}

.streamingContainer {
  width: 100%;
  /* min-width: 80%; */
  /* height: 100%; */
  /* min-height: 75vw; */
  min-height: 55vh;
  /* min-width: 65vh; */
  max-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aligned-video-component {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.streamcomponent {
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

#video-container {
  width: 100%;
  object-fit: contain !important;
  min-height: 55vh;
  max-height: 75vh;
}

video {
  width: 100%;
  height: 100%;
  //object-fit: cover !important;
  //background:  $color-black-primary;
  //border-radius: 20px;
}

.selfSharingVideoPlayer {
  //width: 10vw;
  width: auto !important;
  max-height: 10vw;
  border-radius: 20px !important;
}

.remoteVideoPlayer {
  //width: 10vw;
  width: auto !important;
  max-height: 10vw;
  border-radius: 20px !important;
}

#videoMediaControlOverlay {
  border-radius: 20px;
}

.VideoOverlay {
  //opacity: 0;
  z-index: 10;
  //background: rgba(0, 0, 0, 0.4);
  //border-radius: 20px;
}

.VideoOverlayButtons {
  //opacity: 0;
  //z-index: 10;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

.VideoOverlayRemoteView {
  z-index: 10;
  //background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

/*
.VideoOverlay:hover{
  background: rgba(0, 0, 0, 0.4);
  //opacity: 1;
}*/

/*
#videoMediaControlOverlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  z-index: 1000000;
  opacity: 0;
  
}*/

#video-rise-hand {
  z-index: 20;
  //max-width: 25%;
  margin-top: 2.5%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  margin-bottom: 4.5%;
  box-shadow: 1px 1px 20px 20px #00000014;

  //box-shadow: 1px 1px 20px 20px #00000014;
}

.viewerDataContainer {
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.viewerDataContent {
  white-space: nowrap;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 400 !important;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  margin: 0;
}

#video-rise-hand .remoteFullScreen {
  margin-top: 0%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 4.5%;
}

#video-main-streaming-sharing-screen {
  left: 2.5%;
  bottom: 2%;
  z-index: 100;
  margin-top: 1.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 1.5%;
}

#video-main-streaming-sharing-screen .remoteFullScreen {
  margin-top: 0%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 4.5%;
}

.remoteFullScreen {
  margin-top: 4.5% !important;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
  margin-bottom: 6.5% !important;
}

.fullscreen-enabled {
  object-fit: scale-down;
}

#streamControls,
#streamControlsRemoteView {
  transition: opacity 0.6s ease-out;
}

.inputOptionsContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

#InputOptionsVideoPreview {
  width: 100%;
  height: 100%;
  min-height: 25vh;
  max-width: 360px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  background-color:  $color-black-primary;
  margin-bottom: 1.5%;
}

.inactive {
  opacity: 0;
  cursor: none;
}

#videoMediaControlOverlay:hover {
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

.videoMediaControlOverlayHover {
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

.overlayButtons {
  color: white;
  width: 28px;
  opacity: 0.75;
  cursor: pointer;
}

.shareScreenContainer {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.shareScreenContainer p {
  color: $color-primary-color;
  font-weight: bold !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  margin-left: 1vw;
  opacity: 0.75;
}

.overlayButtons:hover {
  animation: scaleOverlayButton 0.2s 1;
  width: 30px;
  opacity: 0.9;
}

@keyframes scaleOverlayButton {
  from {
    width: 28px;
  }
  to {
    width: 30px;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.volumeSlider {
  position: absolute !important;
  bottom: 10%;
  width: 9vw !important;
  min-width: 135px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.visible {
  display: flex !important;
  opacity: 1;
}

.hidden {
  display: none !important;
  opacity: 0;
}

.rc-slider-handle {
  border: solid 2px #fff !important;
}

.rc-slider-track {
  background-color: var(--color-primary-color);
}

.fullVideoContainer {
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //border-radius: 20px;
  position: relative;
  @media (max-width: 1024px) {
    position: initial;
  }
}

.buttonManager {
  position: absolute;
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 4vh;
  bottom: 0;
  padding-bottom: 2.5%;
  padding-right: 2.5%;
  padding-left: 2.5%;
  background: rgba(0, 0, 0, 0.6) !important;
}

.TopbuttonManager {
  position: absolute;
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 4vh;
  top: 0;
  padding-top: 2.5%;
  padding-right: 2.5%;
  padding-left: 2.5%;
  //background: rgba(0, 0, 0, 0.6) !important;
}

.settingsController {
  position: absolute;
  right: 2%;
  top: 50%;
}

.SettingsModal {
  min-height: 50vh;
}

.SettingsModal .modal-content {
  //margin-top: 10%;
  min-height: 100%;
}

.SettingsModal .nav,
.SettingsModal .nav-tabs .nav-link.active {
  background-color: rgba(255, 255, 255, 0) !important;
  //border-color: #dee2e6 #dee2e6 var(--color-primary-color);
  border: 1px solid transparent;
  color:  $color-black-primary;
  min-height: 100%;
}

.SettingsModal .nav,
.SettingsModal .nav-tabs .nav-link {
  //border: 1px solid transparent;
  color: #909090;
}

.SettingsModal .nav-item {
  color: black !important;
}

.SettingsModal #left-tabs-example {
  background-color: rgb(211, 211, 211);
  color: black !important;
}

.SettingsModal .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: rgb(211, 211, 211) !important;
  color: black !important;
}

.SettingsModal .Btn-rojo {
  margin-bottom: 1%;
}

.SettingsModal .nav-tabs .nav-item {
  margin-bottom: -1px;
}

.SettingsModal .nav-tabs .nav-link {
  margin-right: 0.5%;
}

.Btn-save {
  background-color: #fff;
  color: $color-primary-color;
  border: 1.5px solid $color-primary-color;
}

.SettingsModal .Btn-rojo,
.SettingsModal .Btn-save {
  margin-left: 1%;
  margin-right: 1%;
}

.SettingsModal button:focus {
  outline: 0;
}

.SettingsModal button:hover {
  transform: scale(1.1);
}

.optionContainer {
  align-items: flex-start !important;
  justify-content: flex-start !important;
  display: flex !important;
  flex-direction: column !important;
}

.optionContainer-item {
  margin-bottom: 1%;
}

.right-center-content {
  display: flex;
  justify-items: center;
  align-items: center;
  padding-top: 1%;
  text-align: right;
}

.StreamingOptionsFooter {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  text-align: right;
}

.SettingsModal .nav-item div {
  display: flex;
  flex-direction: row;
  height: 100% !important;
  width: 100% !important;
  align-items: center;
  //justify-content: center;
}

.SettingsModal .nav-item div img {
  max-width: 20px;
  margin-right: 20px;
}

.SettingsModal .nav-item div p {
  margin: 0;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#dropdown-camera-button {
  background-color: $color-primary-color;
  border-width: 0;
}
#dropdown-camera-button:focus {
  background-color: $color-primary-color;
  color: white;
  border-width: 0;
  box-shadow: 0 0 0 0.2rem rgba($color-secondary-color, 0.5) !important;
}

.buttonManagerRemoteView {
  position: absolute;
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 4vh;
  bottom: 0;
  padding-bottom: 2.5%;
  padding-right: 2.5%;
  padding-left: 2.5%;
  //background: rgba(0, 0, 0, 0.6) !important;
}

.fullscreenbtn {
  position: absolute;
  right: 5%;
  bottom: 10%;
}

.resizeScreen {
  position: absolute;
  right: 5%;
  bottom: 4.5%;
  margin-bottom: 6%;
  margin-right: 5%;
}

.swapScreen {
  position: absolute;
  right: 5%;
  bottom: 3.5%;
}

.screenSharebtn {
  position: absolute;
  right: 15%;
  bottom: 10%;
}

.screenSharebtnSharing {
  position: absolute;
  right: 10%;
  bottom: 10%;
}

.volumeController {
  width: 28px;
  margin-right: 10%;
  opacity: 0.75;
}

@media (min-width: 768px) and (max-width: 992px) {
  .messageUserAvatar {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .messageUserAvatar {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 768px) {
  .mainVideoContainer {
    //min-height: 75vh;
    //min-height: 650px;
    //padding-top: 10vh;
    //padding-bottom: 10vh;
  }

  .chatSize {
    max-height: 52.5vh;
  }

  .vertical-center {
    width: 95%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.fullscreen-enabled {
  //background: $color-primary-color;
  height: 100vh !important;
  width: 100vw !important;
  border-radius: 0px;
}

.fullscreen-enabled .buttonManager {
  height: 5vh;
  border-radius: 0px;
}

.fullscreen-enabled .volumeSlider {
  bottom: 20%;
}

.fullscreen-enabled .screenSharebtn {
  right: 7%;
  bottom: 20%;
}

.fullscreen-enabled .screenSharebtnSharing {
  right: 6%;
  bottom: 20%;
}

.fullscreen-enabled .fullscreenbtn {
  bottom: 20%;
  right: 2%;
}

.fullscreen-enabled #video-Element,
.fullscreen-enabled .VideoOverlay {
  height: 100vh !important;
  width: 100vw !important;
  border-radius: 0px;
}

.fullscreen-enabled video {
  //object-fit: contain !important;
}

#video-element-remote {
  //height: 10vw !important;
}

#video-Element video {
  height: 100%;
  left: 0;
  object-fit: contain !important;
  position: absolute !important;
  top: 0;
  width: 100%;
}

#video-Element video {
  position: relative;
}

.remoteViewContainer {
  position: absolute;
  right: 2.5%;
  top: 2.5%;
}

.extraSizeRemoteView {
  //height: 14vw !important;
}

@media (max-width: 768px) {
  .mainVideoContainer {
    margin-top: 2vh;
    //min-height: 25vh;
    margin-bottom: 2vh;
  }

  .mainVideoContainerVideo {
    //min-height: 25vh;
  }

  .messageUserAvatar {
    width: 8.5vw;
  }

  .mainVideoContainerInstructions {
    //min-height: 650px;
  }

  .chatSize {
    max-height: 14vh;
  }

  .vertical-center {
    width: 95%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media (max-width: 1200px) {
  .mainVideoContainerInstructions {
    min-height: 850px;
  }
}

.mainVideoContainerInstructions {
  min-height: 650px;
}

@media screen and (min-width: 1600px) {
  .svg-streaming {
    margin-right: -8rem;
  }

  .configure_before {
    // width: 104%;
  }
  .choose_options {
    position: absolute;
    right: 10rem;
    margin-top: -2rem;
  }

  .mainVideoContainerInstructions {
    min-height: 650px;
  }

  .start_streaming {
    padding: 0px;
    text-align: center;
    margin-right: 15rem;
  }
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: $color-primary-color;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 96, 0.25);
}

.remote-video-loader {
  display: none !important;
}

@media (min-width: 1025px) {
  .remote-video-loader {
    display: flex !important;
  }
}

.AudioVisualiserContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AudioVisualiserContainer img {
  width: 20px !important;
  height: 20px !important;
  margin-right: 7px;
}

.AudioVisualiserDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: gray;
  margin-right: 2.5px;
}

.AudioVisualiserDotActive {
  background-color: $color-primary-color;
}

.AudioVisualiserDotInActive {
}

.ErrorSettingsMediaDevice img {
  display: flex;
  align-self: center;
  justify-self: center;
  margin-right: 15px;
  width: 25px !important;
  height: 25px !important;
}

.ErrorSettingsMediaDeviceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ErrorSettingsMediaDeviceRow p {
  margin: 0;
}

@import "../../../assets/css_general/InvisionExportStyles";


.DiaMes{
  display:flex;
  flex-direction: column;
  .Dia{
    font-size: 50px;
    line-height: 1;
    margin-left: 6px;
    display: inline;
    color: black;
    font-weight: 600 !important;
   }
  .Mes{
    font-size: 22px;
    color: $color-primary-color !important;
    display: inline;
    margin-left: 7px;
    text-align: center;
    font-weight: 600 !important;

  }


  @media (max-width:768px ){
    display: flex;
    flex-direction: column;
    justify-content: center;
    .Dia{
      font-size: 30px;
      margin-left: 6px;
     }
    .Mes{
      font-size: 15px;
      color: $color-primary-color;
      margin-top:8px;
      font-weight: 600 !important;
  
    }
  }
  @media(min-width:1600px){
    .Mes{
      font-size: 26px;
    }
    .Dia{
      font-size: 55px;
      
     }
  }
}
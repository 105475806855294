
.Navbar_search{
  .Nav {
    padding: 17px 32px;
    width: 100%;
    color: white;
    position: fixed;
    z-index: 10;
    top: 0;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .Logued-div {
      display: flex;
      justify-content: space-between;

      img {
        margin-left: 15px;
        border: 1px solid white;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        object-fit: cover;
        @media(max-width: 768px){
          margin-left: 10px !important;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        text-align: right;

        .Name {
          color: white;
          font-size: 21px;
        }

        .Mode {
          color: #7A7883;
          font-size: 17px;
        }


      }
    }
  }



}

.Texto-div{
  @media (max-width: 768px){
    margin-top:0px;
  }
  // margin-top: 80px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  p{
    font-size: 17px;
  }


  .Search-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56%;
    margin-top: 15px;
    position: relative;
    input {

      padding-left: 60px;
      padding-top: 29px;
      padding-bottom: 30px;
      font-size: 18px;
      border-radius: 50px;
      margin-top: 30px;

    }

    img {
      position: absolute;
      left: 20px;
      top: 48px;
    }
  }
}


.canvasjs-chart-credit{
  display: none;
}
.stats_title{
  text-align: left;
    font-size: 1.5em;
    z-index: 999999;
    position: relative;
    margin-bottom: 0;
    bottom: 1.5rem;
    margin-top: 3rem;
}
/* #canvasjs-react-chart-container-0{
  box-shadow: 0px -48px 0px 37px white, 0px -48px 0px 38px rgba(0, 0, 0, 0.125);
} */
.profile_stats{
  box-shadow: 0px -13px 0px 37px white, 0px -13px 0px 38px rgba(0, 0, 0, 0.125);
  margin-top: 5rem;
}
/* #canvasjs-react-chart-container-0::after{
  content: "";
    display: block;
    width: 77rem;
    background: rgba(0, 0, 0, 0.125);
    color: rgba(0, 0, 0, 0.125);
    height: 1px;
    z-index: 999999999999999999999;
    position: absolute;
    bottom: 10px;
} */
.profile_stats_select{
  position: absolute;
  left: 62%;
  width: 14rem;
  height: 3rem;
  z-index: 9;
  margin-top: -5rem;
  border-radius: 2rem;
  padding-left: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.users_stats{
  position: absolute;
  right:10%;
}
.users_stats span{
  font-size: 2em;
  font-weight: bold;
}
.users_stats p{
  font-size: 0.8em;
  color: grey;
}
.canvasjs-chart-tooltip div{
  padding: 1rem!important;
}


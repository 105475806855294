@import "../../../assets/css_general/InvisionExportStyles";


.menu_ban2{
  box-shadow: 1px 3px 1px 1px #373445;
}
.menu_ban{
  background: $color-black-primary;
  display: flex;
  flex-direction: row;
  color: #fff;
  padding: 0.5rem 1rem 1rem 2rem;
  align-items: flex-end;
  height: 5rem;
}
.Logo3{
  width: 3rem;
  height: 3rem;
}
.moderation_ban_titulo{
  font-size: 21px;
    margin-bottom: 10px;
    margin-left: 1rem;
}
.leave-streaming_ban{
  float: right;
    position: relative;
    bottom: 3.5rem;
    border-radius: 50px;
    padding: 0px 16px;
    font-size: 17px;
    height: 32px;
    border: 0;
    background-color: #908E99;
    color: #fff;
    right: 2rem;
}
.NombreCat{

  h1{
    font-size: 2.5rem;
    font-weight: 600;
  }

  h2{
    color: #ff5a60;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .Hor{
    color: #7a7883;
    display: flex;
    font-weight: 500;
    margin-top: 4px;
    font-size: 1rem;
    img{
      width: 15px;
      margin-right: 5px ;
    }
  }


}
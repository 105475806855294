.Nav-menu {
  li {
    text-align: center;

    span {

      color: #7A7883;

    }
  }

  i {
    font-size: 25px;
    color: #b3b8c1;
  }

  li {
    &:hover {


    i {
      color: var(--color-primary-color);

    }

    // span {
    //   color: #fff !important;

    // }
  }
}

.activo {
  i {
    color: var(--color-primary-color);

  }

  // span {
  //   color: #fff !important;

  // }
}

}
/* Skill Categories assets*/

.skill-categories {
  .skill-categories-content {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    img {
      @media(max-width: 768px){
        width:75px;
        height:75px;
      }
      width: 100px;
      height: 100px;
      border-radius: 10px;
    }
    .skill-categories-elements {
      flex-direction: column;
      padding: 0 1rem;
      p {
        color: grey;
      }
      h2 {
        font-size: 23px;
        margin-bottom: 0;
      }

      .skill-sub-category-content {
        input[type="checkbox"] {
          display: inline-block !important;
          flex: none;
          margin-right: 10px;
          margin-left: 10px;
        }
    
        label{
          display: flex;
          align-items: center;
        }
      }

    }
    .save{
      padding: 0px 17px;
      width: 6rem;
      height: 2rem;
      font-size: 16px;
      margin-left: 15px;
      align-self: flex-end;
    }
  }
}
.search_influencer{
    .Influencer_card .Div-txt p {
        font-size: 18px;
        font-weight: 500 !important;
        margin-bottom: 8px;
        margin-top: 5px;
        text-align: center;
    }
    .Influencer_card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 10px 17px 0px #e3e3e3;
        //width: 100%;
        //padding-top: 15px;
        //width: 100%;
        //height: 100%;
        //min-height: 13rem;
    }
    .Influencer_card .Puntuacion {
        width: auto;
    }
    .Influencer_card .Div-img .Foto {
    width: 7rem;
    height: 7rem;
    }
    
}
@media screen and (max-width: 1115px){
    .search_influencer .Influencer_card .Div-txt p {
        font-size: 16px;
    }
}
@media (min-width: 1600px) {
    .search_influencer{

        .Influencer_card .Div-txt p {
            font-size: 20px;
        }
        .Influencer_card .Div-img .Foto {
            width:7.5rem;
            height: 7.5rem;
            }
        
    }
  }

.Search-div-peq{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56%;
  margin-top: 15px;
position: relative;
input {

  padding-left: 60px;
  padding-top: 29px;
  padding-bottom: 30px;
  font-size: 18px;
  border-radius: 50px;
  margin-top: 30px;

}

 img {
  position: absolute;
  left: 20px;
  top: 48px;
}

  i{
    font-size: 30px;
    position: absolute;
    left: 18px;
    top: 45px;
    color: #B3B8C1;
  }
}

#container-circles {
  display: none;
}

.progress-bar {
  background-color: #ff5a60;
}

.vjs-icon-av-perm {
  font-family: videojs-record !important;
  font-weight: normal !important;
}

.vjs-icon-volume-high, .video-js .vjs-mute-control .vjs-icon-placeholder {
  font-family: VideoJS !important;
  font-weight: normal !important;
}

.vjs-icon-fullscreen-enter, .video-js .vjs-fullscreen-control .vjs-icon-placeholder {
  font-family: VideoJS !important;
  font-weight: normal !important;
}

.vjs-icon-record-start {
  font-family: videojs-record !important;
  font-weight: normal !important; 
}

.vjs-icon-record-stop {
  font-family: videojs-record !important;
  font-weight: normal !important;
}

.vjs-icon-play, .video-js .vjs-play-control .vjs-icon-placeholder, .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  font-family: VideoJS !important;
  font-weight: normal !important;
}
@import "../../../assets/css_general/InvisionExportStyles";
.profile_title{
  font-weight:500;
  position: relative;
  left: 4rem;
  font-size: 1.5em;
}
.div_profile{
  margin-top: 3rem;
    margin-left: 5rem;
}
.public_profile{
  input[type="text"]{
    // width: 25rem;
    border: 1px solid #D8DCE5;
    border-radius: 5rem;
    height: 2.5rem;
  }
  select{
    width: 11rem;
    margin-right: 2rem;
    height: 2.5rem;
    border-radius: 3rem;
    border: 1px solid #D8DCE5;
    padding-left: 1rem;
  }
  input[type="date"]{
    width: 11rem;
    margin-right: 2rem;
    height: 2.5rem;
    border-radius: 3rem;
    border: 1px solid #D8DCE5;
  }
  .col-7{
    max-width: 60.5%!important;
  }
  textarea{
    border: 1px solid #D8DCE5;
  }
  p{
    color:#87858F;
    font-size: 0.9em;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
.Menu-live-Events-publish{
  display: flex;
  justify-content: space-around;
  width: 85%;
  a{
    font-size: 18px;
    color: $color-grey-primary;
    padding-bottom: 10px;

    text-align: center;
    &.active_live_events{
      color: $color-active_link;
      border-bottom: 4px solid $color-primary-color;

      &:hover{
        cursor: initial;
      }
    }
    &:hover{
      color: white;
      cursor: pointer;
    }
  }
}
.Nav2{
  padding: 0px 32px;
}
.container_my_balance {
  padding-top: 9rem;
}

#mybalance {
  padding-top: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.Logo {
  width: 250px;
  margin: auto;
}

.text-center {
  text-align: center;
}

.float-right {
  float: right;
}

.border-right-gray {
  border-right: solid 2px gray;
}

.card {
  border-radius: 7px;
  padding: 25px;
}

.font-x-large {
  font-size: x-large;
}

.color-green {
  color: rgb(6, 192, 6);
}

.font-weight-700 {
  font-weight: 700 !important;
}

.inline-grid {
  display: inline-grid;
}

.padding-right-10 {
  padding-right: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.container_my_balance {
  p, h1, h2, h3, h4, h5 {
    text-align: center;
  }
}
